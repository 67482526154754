import { uploadJson } from './uploadJson';
import { getPlanData } from './GetPlanData';

export const createNewFolder = async () => {
  try {
    const response = await getPlanData();
    if (!response) return null;
    const { planResponse } = response;

    const folderName = prompt('Enter new folder name');
    if (!folderName) {
      console.log('User not specified name using default one');
      return;
    }

    const newFolderData = {
      name: folderName,
      visible: 0,
      children: [],
      icon: '/static/icons/DesktopIcons6.svg',
    };

    const updatedPlan = {
      ...planResponse,
      static: {
        ...planResponse.static,
        children: [...planResponse.static.children, newFolderData],
      },
    };

    uploadJson('/plan', updatedPlan);

    console.log(planResponse);
  } catch (e) {
    console.log(e);
  }
};
