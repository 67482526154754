import React from 'react';

type WordNavProps = {
  list: string[];
  navIcon: string;
  arrowLeft: string;
  arrowRight: string;
};

export const WordNav = ({ list, navIcon, arrowLeft, arrowRight }: WordNavProps) => {
  return (
    <nav className="w-full flex gap-1">
      <div className="flex justify-between w-full rounded px-2 items-center bg-[#D9D9D9]">
        <ul className="flex gap-4 text-[.8rem]">
          {list.map((oneList, key) => {
            return (
              <li
                className="py-1 text-black font-medium rounded cursor-pointer"
                key={key}
              >
                <span className={key === 1 ? 'border-b-2 border-word-borderBlue' : ''}>
                  {oneList}
                </span>
              </li>
            );
          })}
        </ul>
        <span className="cursor-pointer">
          <img src={navIcon} alt="star" />
        </span>
      </div>
      <div className="flex gap-1 ">
        <button className="px-1 bg-[#D9D9D9] rounded">
          <img src={arrowLeft} alt="arrow" />
        </button>
        <button className="px-1 bg-[#D9D9D9] rounded">
          <img src={arrowRight} alt="arrow" />
        </button>
      </div>
    </nav>
  );
};
