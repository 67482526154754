import React from 'react';
import { PaperType } from '../../types/generalTypes';
import { TextAreaWrapper } from '../TextAreaWrapper';

export const Paper = ({ hight, text }: PaperType) => {
  return (
    <div className="max-w-[1400px] w-full mx-auto border-2 border-word-paperBorder bg-white ">
      <div className="flex flex-col min-h-[1000px] py-[5rem] px-[3rem] ">
        {hight && <h1 className="font-bold text-lg pb-8">{hight}</h1>}
        <div className=" ">
          {text.map((oneText, key) => {
            return (
              <TextAreaWrapper
                key={key}
                Custom={<div />}
                isHtml
                path={`windowContents.word.paper.text.${key}`}
              >
                {oneText}
              </TextAreaWrapper>
            );
          })}
        </div>
        <textarea className="flex-1 mt-2 px-1 resize-none outline-0"></textarea>
      </div>
    </div>
  );
};
