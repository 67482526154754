import { IconMenu } from '../../icon/IconMenu';
import * as React from 'react';
import { Fragment } from 'react';

import { ModalTypes, ModalTypesKeys } from '../../hooks/UseModal';
import { Desktop, DesktopApps } from '../../utils/types/data';

type ScreenDockIcon = Required<DesktopApps>;

export const modalJsonTypes: Record<ScreenDockIcon['type'], ModalTypesKeys> = {
  mailWindow: 'mailWindow',
  browserWindow: 'browserWindow',
  wordWindow: 'wordWindow',
  videoChatWindow: 'videoChatWindow',
  fileManagerWindow: 'fileManagerWindow',
  graphWindow: 'graphWindow',
  articleWindow: 'articleWindow',
  videoPlayerWindow: 'videoPlayerWindow',
  settingsWindow: 'settingsWindow',
};

export function ScreenDock(props: {
  modal: ModalTypes;
  apps: Desktop['content']['dock']['apps'];
  onClick: (modalKey: ModalTypesKeys) => void;
}) {
  return (
    <div className="flex relative gap-4 justify-center items-start self-center px-3 py-2.5 mt-auto rounded-2xl border border-solid border-gray-300 border-opacity-30 shadow-lg backdrop-blur-[32px] bg-white bg-opacity-20 max-md:flex-wrap max-md:mt-10 max-md:max-w-full">
      {/* <div className="content-center w-12 h-12 rounded-lg bg-white bg-opacity-30" /> */}
      {props.apps.map((app, index) => (
        <Fragment key={index}>
          <IconMenu
            src={app.icon}
            isActive={app.type && props.modal[modalJsonTypes[app.type]]}
            badge={app.badge}
            onClick={() => {
              app.type && props.onClick(modalJsonTypes[app.type]);
            }}
            showWhiteBackground={app.showWhiteBackground}
          />
          {app.addDelimiter && (
            <div className="w-px h-full bg-white bg-opacity-30 rounded-xl"></div>
          )}
        </Fragment>
      ))}
    </div>
  );
}
