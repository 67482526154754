import { Outlet, RouteObject } from 'react-router-dom';
import { screenLoader } from './loaders/screenLoader';
import ErrorBoundary from '../components/ErrorBoundary';
import Screen from '../components/screen';

import { AppWindowZIndexProvider } from '../components/screen/AppWindowZIndexContextProps';
import { SceneProvider } from '../states/useSceneData';

const DayScreenRoute: RouteObject = {
  path: '/:day/:scene/:pc',
  id: 'screen',
  element: <Outlet />,
  errorElement: <ErrorBoundary />,
  children: [
    {
      path: '',
      element: (
        <SceneProvider>
          <AppWindowZIndexProvider>
            <Screen />
          </AppWindowZIndexProvider>
        </SceneProvider>
      ),
      loader: screenLoader,
    },
  ],
};

export default DayScreenRoute;
