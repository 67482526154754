import React from 'react';
import { Social } from '../../../types/generalTypes';
import HeaderNavItem from './HeaderNavItem';
import ProfileDetail from './ProfileDetail';
import ProfileList from './ProfileList';
import { useCurrentWindows } from '../../../states/useCurrentWindows';

const SnapSphere = (props: { data: Social }) => {
  const currentWindows = useCurrentWindows((state) => state.currentWindows);

  return (
    <div className="flex justify-center mt-6">
      <div className="flex flex-col w-[700px]">
        <div className="flex justify-between mb-6">
          <img src={props.data.logo} alt="" />
          {props.data.headerNavItems.map((item, idx) => (
            <HeaderNavItem
              icon={item.icon}
              title={item.title}
              count={item.count}
              key={idx}
            />
          ))}
        </div>
        <div className="flex">
          <ProfileDetail detailData={props.data.profileData} />
          <ProfileList
            profileList={props.data.profilesList}
            coords={currentWindows.browserWindow?.coords}
          />
        </div>
      </div>
    </div>
  );
};

export default SnapSphere;
