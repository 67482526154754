import React from 'react';

import { ScreenData } from '../../routes/loaders/screenLoader';
import UrlBar from './UrlBar';
import SearchBarArea from './SearchBarArea';
import { SearchResult } from './SearchResult';
import { Desktop } from '../../utils/types/data';
import { FeedScreenCont } from '../feed-screen/FeedScreenCont';
import SnapSphere from './snap-sphere/SnapSphere';
import { ArticleResult } from '../articleWindow/ArticleResult';

// Search client uses all data (snap, feed, result, url)
const BrowserClient = (props: {
  data: ScreenData;
  content?: keyof Desktop['windowContents'];
}) => {
  const { data, content } = props;
  const currentClient = content ?? 'search';

  const renderComponents = () => {
    switch (currentClient) {
      case 'searchResult': {
        const content = data.dayData.windowContents[currentClient];
        if (!content) throw new Error('Missing searchResultData');
        return <SearchResult data={content} icons={content.icons} />;
      }
      case 'search': {
        const content = data.dayData.windowContents[currentClient];
        if (!content) throw new Error('Missing Search Data');
        return (
          <>
            <UrlBar icons={content.icons} />
            <SearchBarArea icons={content.icons} />
          </>
        );
      }
      case 'feed': {
        const content = data.dayData.windowContents[currentClient];
        if (!content) throw new Error('Missing Feed data');
        return (
          <>
            {/*<SnapSphere screenSize={tempScreenSize} />*/}
            <FeedScreenCont data={content} icons={content.icons} />
          </>
        );
      }
      case 'social': {
        const content = data.dayData.windowContents[currentClient];
        if (!content) throw new Error('Missing Social data');
        return <SnapSphere data={content} />;
      }
      case 'articleResult': {
        const content = data.dayData.windowContents[currentClient];
        if (!content) throw new Error('Missing article data');
        return <ArticleResult data={content} />;
      }
    }
  };

  return (
    <>
      <div className={'overflow-y-auto bg-white w-full h-full'}>
        <div
          className={
            currentClient === 'graphWindow'
              ? 'w-full h-full flex gap-5 max-md:flex-col  pb-8'
              : 'w-full flex gap-5 max-md:flex-col'
          }
        >
          <div
            className={
              currentClient === 'graphWindow'
                ? 'w-full h-full flex-col content-center'
                : 'w-full flex-col content-center'
            }
          >
            {renderComponents()}
          </div>
        </div>
      </div>
    </>
  );
};

export default BrowserClient;
