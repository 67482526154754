import { ModalTypes, ModalTypesKeys } from '../../hooks/UseModal';
import { EmailClient } from '../mail-screen/EmailClient';
import BrowserClient from '../browser-screen/BrowserClient';
import Window from '../window/Window';
import * as React from 'react';
import { ScreenData } from '../../routes/loaders/screenLoader';

import { Desktop, DesktopApps, WindowIcons } from '../../utils/types/data';
import { WordWindow } from '../word-screen/WordWindow';

import VideoChatClient from '../video-chat-screen/VideoChatClient';
import { FileManagerWindow } from '../fileManagerWIndow/FileManagerWindow';
import { GraphWindow } from '../graphWindow/GraphWindow';
import { ArticleWindow } from '../articleWindow/ArticleWindow';
import { VideoPlayerWindow } from '../video-player/VideoPlayerWindow';
import { SettingsWindow } from '../settingsWindow/SettingsWindow';
import { useCurrentWindows } from '../../states/useCurrentWindows';

export const Windows = (props: {
  data: ScreenData;
  modal: ModalTypes;
  browserWindowContent?: keyof Desktop['windowContents'];
  closeModal: (modalType: ModalTypesKeys) => void;
  icons: WindowIcons;
  desktopData: Desktop;
}) => {
  const { data, modal, closeModal, browserWindowContent, icons, desktopData } = props;
  const currentWindows = useCurrentWindows((state) => state.currentWindows);
  const renderClientByType = (type: ModalTypesKeys) => {
    switch (type) {
      case 'mailWindow': {
        const content = data.dayData.windowContents.email;
        if (!content) return null;
        return <EmailClient data={content} />;
      }
      case 'browserWindow': {
        return <BrowserClient data={data} content={browserWindowContent} />;
      }
      case 'wordWindow': {
        const content = data.dayData.windowContents.word;
        if (!content) return null;
        return <WordWindow data={content} />;
      }

      case 'videoChatWindow': {
        const content = data.dayData.windowContents.videoChat;
        if (!content) return null;
        return <VideoChatClient data={content} />;
      }
      case 'fileManagerWindow': {
        const content = data.dayData.windowContents.fileManager;
        if (!content) return null;
        return <FileManagerWindow data={content} />;
      }
      case 'graphWindow': {
        const content = data.dayData.windowContents.graphWindow;
        if (!content) return null;
        return <GraphWindow data={content} />;
      }
      case 'articleWindow': {
        const content = data.dayData.windowContents.articleWindow;
        if (!content) return null;
        return <ArticleWindow data={content} />;
      }
      case 'videoPlayerWindow': {
        const content = data.dayData.windowContents.videoPlayer;
        if (!content) return null;
        return <VideoPlayerWindow data={content} />;
      }
      case 'settingsWindow': {
        return <SettingsWindow data={data} />;
      }
    }
  };

  const windowInformation = (window: keyof ModalTypes): DesktopApps | null => {
    const headerApps = desktopData.content.header.apps ?? [];
    const desktopApps = [...desktopData.content.dock.apps, ...headerApps];

    const actualWindow = desktopApps.find((win) => {
      if (!win.type) return;
      return win.type === window;
    });
    if (!actualWindow) {
      return null;
    }
    if (!actualWindow.type) return null;

    return {
      ...actualWindow,
      coords: currentWindows[actualWindow.type]?.coords ?? actualWindow.coords,
      fullscreen:
        currentWindows[actualWindow.type]?.fullscreen ?? actualWindow.fullscreen,
    };
  };

  return (
    <>
      {(Object.keys(modal) as ModalTypesKeys[]).map((window) => {
        return (
          <Window
            type={window}
            windowInfo={windowInformation(window)}
            key={window}
            // TODO: Maybe tablist do better?
            tabList={
              data.dayData.content.dock.apps.find((app) => app.type === window)
                ?.tabList ?? []
            }
            open={modal[window]}
            onClose={() => closeModal(window)}
            icons={icons}
          >
            {renderClientByType(window)}
          </Window>
        );
      })}
    </>
  );
};
