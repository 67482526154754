import React from 'react';
import { Coords } from '../../../types/generalTypes';
import { calculateHeight } from '../../../utils/functions/calculateHeight';

const ProfileList = (props: {
  profileList: { image: string }[];
  coords: Coords | undefined;
}) => {
  return (
    <div
      style={{ maxHeight: calculateHeight(props.coords?.h, 150) }}
      className={`profile-list w-2/3 grid grid-cols-2 gap-1.5  overflow-auto`}
    >
      {props.profileList.map((profile, idx) => (
        <img key={idx} src={profile.image} alt="" className="w-full" />
      ))}
    </div>
  );
};

export default ProfileList;
