import { DataType } from '../day-route/DayScreen';

export type ContextMenuActionTypes = 'duplicate' | 'rename' | 'createFolder';

type ContextMenuProps = {
  screenType: DataType;
  position: { x: number; y: number };
  open: boolean;
  onClick?: (type: ContextMenuActionTypes) => void;
};

type ContextMenuStyle = {
  position?: 'absolute';
  top: string;
  left: string;
};

export const ContextMenu = ({
  position: { x, y },
  onClick,
  screenType,
}: ContextMenuProps) => {
  // async function getData() {
  //   try {
  //     const response = await axios.get('/static/desktops/pc1.json', {
  //       headers: {
  //         'api-key': 'wfA7kzXCgixU5s4BkVzb',
  //       },
  //     });
  //     console.log(response);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }
  //
  // getData();

  // const duplicateIcon = async () => {
  //   const newIconName = `${setIconName.curentIconname}${setIconName.id}`;
  //   try {
  //     const pc1JSONpath = '/static/desktops/pc1.json';
  //     const fetchData = await getJsonData(pc1JSONpath);
  //     console.log(fetchData);
  //     console.log(newIconName);
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     setClicked((isActive) => !isActive);
  //   }
  // };

  const menuStyle: ContextMenuStyle = {
    position: 'absolute',
    top: `${y}px`,
    left: `${x}px`,
  };

  return screenType === 'scene' ? (
    <ul style={menuStyle} className="bg-black py-3">
      <li
        onClick={() => onClick?.('duplicate')}
        className=" px-3 text-white cursor-pointer hover:bg-zinc-700"
      >
        Duplicate
      </li>
      <li
        onClick={() => onClick?.('rename')}
        className=" px-3 text-white cursor-pointer hover:bg-zinc-700"
      >
        Rename
      </li>
    </ul>
  ) : (
    <ul style={menuStyle} className="bg-black py-3">
      <li
        onClick={() => onClick?.('createFolder')}
        className=" px-3 text-white cursor-pointer hover:bg-zinc-700"
      >
        New Folder
      </li>
    </ul>
  );
};
