import { useState } from 'react';

export type ModalTypes = {
  mailWindow: boolean;
  browserWindow: boolean;
  wordWindow: boolean;
  videoChatWindow: boolean;
  fileManagerWindow: boolean;
  articleWindow: boolean;
  graphWindow: boolean;
  videoPlayerWindow: boolean;
  settingsWindow: boolean;
};
export type ModalTypesKeys = keyof ModalTypes;

export const useModal = (openedWindows: ModalTypesKeys[]) => {
  const [modal, setModal] = useState<ModalTypes>({
    mailWindow: openedWindows.includes('mailWindow'),
    browserWindow: openedWindows.includes('browserWindow'),
    wordWindow: openedWindows.includes('wordWindow'),
    videoChatWindow: openedWindows.includes('videoChatWindow'),
    fileManagerWindow: openedWindows.includes('fileManagerWindow'),
    articleWindow: openedWindows.includes('articleWindow'),
    graphWindow: openedWindows.includes('graphWindow'),
    videoPlayerWindow: openedWindows.includes('videoPlayerWindow'),
    settingsWindow: openedWindows.includes('settingsWindow'),
  });

  const openModal = (modalType: ModalTypesKeys) => {
    setModal((prevState) => ({
      ...prevState,
      [modalType]: true,
    }));
  };

  const toggleModal = (modalType: ModalTypesKeys) => {
    setModal((prevState) => ({
      ...prevState,
      [modalType]: !prevState[modalType],
    }));
  };

  const closeModal = (modalType: ModalTypesKeys) => {
    setModal((prevState) => ({
      ...prevState,
      [modalType]: false,
    }));
  };

  const changeModalsState = (modals: ModalTypesKeys[]) => {
    setModal({
      mailWindow: modals.includes('mailWindow'),
      browserWindow: modals.includes('browserWindow'),
      wordWindow: modals.includes('wordWindow'),
      videoChatWindow: modals.includes('videoChatWindow'),
      fileManagerWindow: modals.includes('fileManagerWindow'),
      articleWindow: modals.includes('articleWindow'),
      graphWindow: modals.includes('graphWindow'),
      videoPlayerWindow: modals.includes('videoPlayerWindow'),
      settingsWindow: modals.includes('settingsWindow'),
    });
  };

  return {
    modal,
    toggleModal,
    closeModal,
    openModal,
    changeModalsState,
  };
};
