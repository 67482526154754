import React, { useState } from 'react';
import { FileManagerType } from '../../utils/types/data';
import { FilemanagerNav } from './FilemanagerNav';
import { AsideNav } from './AsideNav';
import { Content } from './Content';

export const FileManagerWindow = (props: { data: FileManagerType }) => {
  const [data, setData] = useState<FileManagerType>(props.data);
  const [currentIconListCount, setCurrentIconListCount] = useState<number>(0);

  const icons = props.data.contentIcons[currentIconListCount];

  const increaseCurrentCountIfZero = () => {
    setCurrentIconListCount((prevState) =>
      prevState < data.contentIcons.length - 1 ? prevState + 1 : prevState,
    );

    const visibleAsideIcons = props.data.asideItem.map((icon) => {
      return { ...icon, hidden: false };
    });

    setData({ ...props.data, asideItem: visibleAsideIcons });
  };

  return (
    <div
      className="relative w-full h-full bg-white pointer-events-none"
      onClick={increaseCurrentCountIfZero}
      id="replace-file-manager-icons"
    >
      <FilemanagerNav navItem={data.navigation} searchIcon={data.navSearch} />
      <div className="w-full h-full relative flex gap-10 px-2">
        <AsideNav asideItem={data.asideItem} />
        <Content contentIcon={icons} data={data} />
        <div className="absolute top-[0] left-[200px] w-[1px] h-full bg-stone-300"></div>
      </div>
    </div>
  );
};
