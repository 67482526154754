import UrlBar from '../browser-screen/UrlBar';
import { FeedIcon } from './FeedIcon';
import { FeedSreenArticle } from './FeedSreenArticle';
import { FeedScreenData } from '../../types/generalTypes';
import { SearchIcons } from '../../types/searchClientTypes';

// FeedScreenData
export const FeedScreenCont = (props: { data: FeedScreenData; icons: SearchIcons }) => {
  const feedData = props.data;
  const { feedArticle } = feedData;
  const { listItem, navAvatar } = feedData.nav;
  const [messageChat, likeIcon] = feedData.feedArticleIcon;

  return (
    <>
      <div className="w-full flex flex-col content-center">
        <UrlBar icons={props.icons} />
        <div className=" max-w-[750px] w-full mx-auto py-16">
          <div className="flex gap-3 items-center w-full">
            <div className="w-[270px]">
              <img src={feedData.nav.navIcon} alt="snap here" />
            </div>
            <div className="flex gap-5 ">
              {listItem.map((oneListItem, key) => {
                const { icon, name, count } = oneListItem;
                return <FeedIcon key={key} icon={icon} text={name} showIcon={count} />;
              })}
            </div>
            <div>
              <img src={navAvatar} alt="nav avatar" />{' '}
            </div>
          </div>
          <div className="px-3 pt-10">
            {feedArticle.map((oneArticle, key) => {
              const { avatar, name, content, gallery } = oneArticle;
              return (
                <FeedSreenArticle
                  key={key}
                  avatar={avatar}
                  name={name}
                  text={content}
                  headerChat={likeIcon}
                  headerLike={messageChat}
                  gallery={gallery}
                />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
