import React from 'react';

interface IconProps {
  src: string;
  text?: string;
  textVariant?: 'black' | 'white';
  onContextMenu?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  truncateText?: boolean;
  iconSize?: 'small' | 'medium' | 'large';
}

export const Icon: React.FC<IconProps> = ({
  src,
  text,
  textVariant = 'white',
  onContextMenu,
  truncateText = true,
  iconSize = 'medium',
}) => {
  const imageSize = {
    small: ' h-[20px] w-[20px]',
    medium: ' h-[40px] w-[40px]',
    large: ' h-[60px] w-[60px]',
  };

  return (
    <div
      onContextMenu={onContextMenu}
      className="flex flex-col  w-[80px] h-fit text-wrap justify-center pt-4"
    >
      <div className={`${imageSize[iconSize]} self-center`}>
        <img className="h-full" src={src} alt={src} />
      </div>
      <div
        className={`block ${truncateText && 'break-all truncate'}  text-center ${textVariant === 'white' ? 'text-white' : 'text-black'}`}
      >
        {text}
      </div>
    </div>
  );
};
