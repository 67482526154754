type feedIconType = {
  icon: string;
  text: string;
  showIcon: number | boolean | undefined;
  iconText?: number;
};

export const FeedIcon = ({ icon, text, showIcon, iconText }: feedIconType) => {
  return (
    <div className="relative flex flex-col items-center justify-center gap-3 rounded-xl py-3 px-10 bg-[#F7F9FA]">
      <div>
        <img src={icon} alt="home" />
      </div>
      <div className="font-medium text-[#777777]">{text}</div>
      {showIcon && (
        <div className="absolute top-0 right-0 py-[1px] px-[8px] bg-[#C990E4] font-medium rounded-[50%] text-[13px]">
          {iconText}
        </div>
      )}
    </div>
  );
};
