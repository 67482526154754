import { HeaderContextMenu } from '../components/headerContextMenu/HeaderContextMenu';
import { HeaderContextMenuActions } from '../components/screen/Header';

interface IconProps {
  src: string;
  onClick?: () => void;
  contextMenu?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  clicked?: boolean;
  contextMenuActions?: HeaderContextMenuActions;
}

export const IconHeader: React.FC<IconProps> = ({
  src,
  onClick,
  contextMenu,
  clicked,
  contextMenuActions,
}) => {
  return (
    <button
      onClick={onClick}
      onContextMenu={contextMenu}
      className="flex gap-5 justify-between whitespace-nowrap shadow-sm"
    >
      <img src={src} alt={src} className="w-4 aspect-square" />
      {clicked && <HeaderContextMenu contextMenuActions={contextMenuActions} />}
    </button>
  );
};
