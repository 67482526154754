import { createStore, useStore } from 'zustand';
import { Desktop, PC } from '../utils/types/data';
import React, { useContext, useRef } from 'react';
import { useLoaderData } from 'react-router-dom';
import { ScreenData } from '../routes/loaders/screenLoader';

type SceneProps = {
  dayData: Desktop | null;
  path: PC | undefined;
};

type SceneData = SceneProps & {
  updateSceneData: (data: Desktop) => void;
  updateSceneBackground: (img: string) => void;
};

type SceneStore = ReturnType<typeof createSceneStore>;

// Because is used in ref you must refresh page in browser to apply changes
const createSceneStore = (initProps?: Partial<SceneProps | null>) => {
  const DEFAULT_PROPS: SceneProps = {
    dayData: null,
    path: undefined,
  };

  return createStore<SceneData>()((set) => ({
    ...DEFAULT_PROPS,
    ...initProps,
    updateSceneData: (data) => set({ dayData: data }),
    updateSceneBackground: (img: string) =>
      set((state) => {
        if (!state.dayData) {
          console.error('In state missing dayData');
          return state;
        }
        return {
          ...state,
          dayData: {
            ...state.dayData,
            content: {
              ...state.dayData.content,
              desktop: {
                ...state.dayData.content.desktop,
                image: img,
              },
            },
          },
        };
      }),
    reset: () => set(DEFAULT_PROPS),
  }));
};

export const SceneContext = React.createContext<SceneStore | null>(null);

type SceneProviderProps = React.PropsWithChildren;

export function SceneProvider({ children }: SceneProviderProps): JSX.Element {
  const data = useLoaderData() as ScreenData;
  const storeRef = useRef<SceneStore>();
  if (!storeRef.current) {
    storeRef.current = createSceneStore(data);
  }
  return (
    <SceneContext.Provider value={storeRef.current}>{children}</SceneContext.Provider>
  );
}

export function useSceneContext<T>(selector: (state: SceneData) => T): T {
  const store = useContext(SceneContext);
  if (!store) throw new Error('Missing BearContext.Provider in the tree');
  return useStore(store, selector);
}
