import { Outlet, RouteObject } from 'react-router-dom';
import HubScreen from '../components/day-route/HubScreen';
import { fetchPlanData } from './RootRoute';

export const HubRoute: RouteObject = {
  path: '/hub',
  id: 'hub',
  loader: fetchPlanData,
  element: <Outlet />,
  children: [
    {
      path: '',
      element: <HubScreen />,
      loader: async () => {
        try {
          const response = await fetch('/static/plan.json'); // Put real data from API
          const days = await response.json();
          const data = {
            days,
          };
          return data;
        } catch (error) {
          if (error instanceof Error) throw new Error(error.message);
          return null;
        }
      },
    },
  ],
};
