import { Icon } from '../../icon/Icon';

import * as React from 'react';
import { Desktop } from '../../utils/types/data';

export function ScreenContent(props: {
  icons: Desktop['content']['desktop']['icons'];
  gridSize: Desktop['content']['desktop']['gridSize'];
}) {
  const { icons, gridSize } = props;
  const gridSizeStyle = {
    gridTemplateColumns: `repeat(${gridSize.x}, minmax(0,1fr))`,
    gridTemplateRows: `repeat(${gridSize.y}, minmax(0,1fr))`,
  };

  return (
    <>
      <div className={`grid relative mx-5 h-auto`} style={gridSizeStyle}>
        {icons.map((icon, i) => {
          const x = icon.position.x;
          const y = icon.position.y;
          const iconStyle = {
            gridRowStart: y,
            gridRowEnd: y + 1,
            gridColumnStart: x + 1,
            gridColumnEnd: x + 1,
          };
          return (
            <div key={i} style={iconStyle}>
              <Icon src={icon.icon} text={icon.text} />
            </div>
          );
        })}
      </div>
    </>
  );
}
