import { Day } from '../types/data';

export const getPlanData = async () => {
  try {
    const response = await fetch(
      `/static/plan.json
`,
      {
        headers: {
          'api-key': 'wfA7kzXCgixU5s4BkVzb',
        },
      },
    ); // Put real data from API
    const days = (await response.json()) as Record<string, Day>;
    const data = {
      planResponse: days,
    };
    return data;
  } catch (error) {
    if (error instanceof Error) throw new Error(error.message);
    return null;
  }
};
