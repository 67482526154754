import React from 'react';

type ImageContainerProps = {
  container?: { class?: string; onClick?: () => void };
  img: { class?: string; src: string; alt?: string };
  children?: React.ReactNode;
};

const ImageContainer = (props: ImageContainerProps) => {
  const { container, img, children } = props;
  const imgClass = `w-full aspect-square p-[.08rem] ${img?.class}`;
  return (
    <>
      <div
        className={container?.class}
        onClick={container?.onClick}
        onMouseDown={(e) => {
          e.stopPropagation();
        }}
      >
        <img loading="lazy" src={img.src} alt={img.alt} className={imgClass} />
        {children}
      </div>
    </>
  );
};

export default ImageContainer;
