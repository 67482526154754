import { uploadJson } from './uploadJson';
import { getPlanData } from './GetPlanData';

export const addDefaultScene = async (sceneName: string) => {
  try {
    const response = await getPlanData();
    if (!response) return null;
    const { planResponse } = response;

    const defaultSceneJson = {
      icon: '/static/icons/DesktopIcons3.svg',
      json: 'desktops/random-screen/random',
      name: 'random',
      visible: 0,
    };

    const updatedPlan = {
      ...planResponse,
      static: {
        ...planResponse.static,
        children: planResponse.static.children.map((child) => {
          if (child.name === sceneName) {
            return { ...child, children: [defaultSceneJson] };
          } else {
            return { ...child };
          }
        }),
      },
    };

    uploadJson('/plan', updatedPlan);

    console.log(updatedPlan);
  } catch (e) {
    console.log(e);
  }
};
