import React, { MutableRefObject, RefObject, useState } from 'react';
import { ParticipantType, PresenterSettings } from '../../types/generalTypes';
import { Participant } from './Participant';

type PresentationScreenProps = {
  grayScreen: string;
  multyCall: boolean;
  presentationImages: string[];
  presenterWindow: ParticipantType;
  presenterSettings: PresenterSettings;
  placeholderUserImage: string;
  videoRef: RefObject<HTMLVideoElement>;
  currentTimeRef: MutableRefObject<number>;
  handleTimeUpdate: () => void;
};

export const PresentationScreen = ({
  grayScreen,
  multyCall,
  presentationImages,
  presenterWindow,
  presenterSettings,
  placeholderUserImage,
  currentTimeRef,
  handleTimeUpdate,
}: PresentationScreenProps) => {
  const [currentPresentationImage, setCurrentPresentationImage] = useState<number>(0);

  const switchToNextImage = () => {
    setCurrentPresentationImage((prevState) => {
      if (prevState === 0) {
        return presentationImages.length - 1;
      } else {
        return prevState - 1;
      }
    });
  };

  const position = () => {
    switch (presenterSettings.position) {
      case 'top-right': {
        return 'top-10 right-2';
      }
      case 'top-left': {
        return 'top-10 left-2';
      }
      case 'bottom-right': {
        return 'bottom-10 right-2';
      }
      case 'bottom-left': {
        return 'bottom-10 left-2';
      }
      default:
        return 'top-5 right-2';
    }
  };

  return (
    <div className={'w-full h-full pb-20'}>
      <div className={`w-3/12  aspect-video absolute ${position()}`}>
        <Participant
          grayScreen={grayScreen}
          multyCall={multyCall}
          name={presenterWindow.name}
          src={presenterWindow.src}
          placeholderUserImage={placeholderUserImage}
          showAnimation
          srcType={'video'}
          currentTimeRef={currentTimeRef}
          handleTimeUpdate={handleTimeUpdate}
        />
      </div>

      <img
        alt={'Presenter Image'}
        id={'presentation-slide-forward'}
        onClick={switchToNextImage}
        src={presentationImages[currentPresentationImage]}
        className={'h-full w-full object-contain object-center'}
      />
    </div>
  );
};
