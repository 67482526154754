import React, { useMemo } from 'react';
import { Divider } from '../Divider';
import { getRandomIcon } from '../../utils/functions/getRandomIcon';
import { TextAreaWrapper } from '../TextAreaWrapper';

type ArticleManageType = {
  iconSrc: string;
  idx: number;
  component?: string;
  hightText: string;
  text: string;
  category: string;
  author: string;
  date: string;
  allArticleIcons: string[];
};

export const ArticleManage = ({
  iconSrc,
  component,
  hightText,
  idx,
  text,
  category,
  author,
  date,
  allArticleIcons,
}: ArticleManageType) => {
  const icon = useMemo(() => {
    return getRandomIcon(iconSrc, allArticleIcons);
  }, [allArticleIcons, iconSrc]);

  const articlePath =
    component === 'SearchResult'
      ? `windowContents.searchResult.bodyArticle.${idx}`
      : `windowContents.articleResult.asideArticle.${idx}`;

  return (
    <article className="flex flex-col justify-items-center gap-1 text-justify">
      <header className="flex gap-2 ">
        <div className="text-[#0077B6] font-bold">
          {icon && (
            <span className="relative top-[4px] inline-block w-max pr-1  rounded-md">
              <img className="w-[21px]" src={icon} alt="icon" />
            </span>
          )}

          <TextAreaWrapper
            Custom={<span className={'text-xl'}></span>}
            path={`${articlePath}.hightText`}
          >
            {hightText}
          </TextAreaWrapper>
        </div>
      </header>
      <TextAreaWrapper
        Custom={<p className="font-semibold text-[1rem]"></p>}
        path={`${articlePath}.text`}
      >
        {text}
      </TextAreaWrapper>
      <div className="flex text-[10px] leading-none h-2.5">
        {category && (
          <>
            <TextAreaWrapper
              Custom={<span className="text-[#0077B6] font-black mr-1"></span>}
              path={`${articlePath}.category`}
            >
              {category}
            </TextAreaWrapper>

            <Divider />
          </>
        )}
        <TextAreaWrapper Custom={<span></span>} path={`${articlePath}.author`}>
          {author}
        </TextAreaWrapper>
        <Divider />
        <TextAreaWrapper Custom={<span></span>} path={`${articlePath}.date`}>
          {date}
        </TextAreaWrapper>
      </div>
    </article>
  );
};
