import { EmailMessage } from '../../types/emailClientTypes';
import * as React from 'react';
import { useState } from 'react';
import { EmailButton } from './EmailButton';

type ReplyFieldProps = {
  emailReplyIcon?: string;
  emailMessage: EmailMessage;
  closeReplyField: () => void;
};
export const ReplyField = ({
  emailReplyIcon,
  emailMessage,
  closeReplyField,
}: ReplyFieldProps) => {
  const [replyContent, setReplyContent] = useState(emailMessage.replyContent);

  return (
    <>
      <div className="w-full border p-4 rounded-2xl my-4">
        <div className={'flex gap-2 pb-1'}>
          <img className={'w-5'} src={emailReplyIcon} />{' '}
          <p>Antwort: {emailMessage.userName}</p>
        </div>
        <div className={'text-lg font-semibold'}>{emailMessage.subject}</div>
        <textarea
          id={'email-reply'}
          rows={10}
          className={'clean-textbox min-h-36 w-full border mt-4 text-sm'}
          onChange={(e) => setReplyContent(e.currentTarget.value)}
          value={replyContent}
        ></textarea>
      </div>
      <EmailButton position={'right'} onClick={closeReplyField}>
        Senden
      </EmailButton>
    </>
  );
};
