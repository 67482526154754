import { Automation, WindowContents } from '../utils/types/data';
import { modalJsonTypes } from '../components/screen/ScreenDock';
import { addOnKeyPress } from '../utils/automations/addOnKeyPress';
import { Dispatch, SetStateAction, useRef } from 'react';

import { AppWindowZIndexContextProps } from '../components/screen/AppWindowZIndexContextProps';
import { ModalTypes } from './UseModal';
import { useLoaderData } from 'react-router-dom';
import { ScreenData } from '../routes/loaders/screenLoader';
import { useForceReset } from '../states/useForceReset';
import { useSceneContext } from '../states/useSceneData';
import { MAIL_CONTENT_ID } from '../utils/constants';

export const useAutomation = (props: {
  setBrowserWindowContent: Dispatch<SetStateAction<keyof WindowContents>>;
  appWindowZIndex: AppWindowZIndexContextProps;
  setIsGreyScreen: Dispatch<SetStateAction<boolean>>;
  setIsScreenLocked: Dispatch<SetStateAction<boolean>>;
  closeModal: (modalType: keyof ModalTypes) => void;
  openModal: (modalType: keyof ModalTypes) => void;
  toggleModal: (modalType: keyof ModalTypes) => void;
  handleAutomationReset: (dayData: ScreenData['dayData']) => void;
}): {
  executeAutomation: typeof executeAutomation;
} => {
  const {
    setBrowserWindowContent,
    appWindowZIndex,
    setIsGreyScreen,
    setIsScreenLocked,
    closeModal,
    openModal,
    toggleModal,
    handleAutomationReset,
  } = props;
  const { setForceReset, forceReset } = useForceReset((state) => state);
  const wordCount = useRef(0);
  const currentWord = useRef('');
  const data = useLoaderData() as ScreenData;
  const { updateSceneData } = useSceneContext((state) => state);

  function executeAutomation(
    automations: Automation[],
    start: number = 0,
    end: number = 0,
    current: number = 0,
  ): void {
    const automation = automations[current];

    if (!automation) {
      return;
    }

    if (current > end) {
      return;
    }
    console.log(current);

    if (automation.type === 'reset') {
      document.addEventListener(
        automation.event,
        () => {
          console.log(data.dayData);
          updateSceneData(JSON.parse(JSON.stringify(data.dayData)));
          handleAutomationReset(data.dayData);
          setForceReset(forceReset);
          setIsGreyScreen(false);
          setBrowserWindowContent(data.dayData.browserDefaultContent);

          setTimeout(() => {
            // exit current function
            return;
          }, 0);
        },
        { once: true },
      );
    }

    if (automation.type === 'delay') {
      setTimeout(() => {
        return executeAutomation(automations, start, end, ++current);
      }, automation.delayInSeconds * 1000);
    }
    if (automation.type === 'actionDelay') {
      setTimeout(() => {
        openModal(modalJsonTypes[automation.appName]);
        return executeAutomation(automations, start, end, ++current);
      }, automation.delayInSeconds * 1000);
    }

    if (automation.type === 'switchContentDelay') {
      setTimeout(() => {
        setBrowserWindowContent(automation.content);
        return executeAutomation(automations, start, end, ++current);
      }, automation.delayInSeconds * 1000);
    }

    if (automation.type === 'app' && automation.event === 'click') {
      if (automation.name === 'dock.closeApp') {
        document.addEventListener(
          'keypress',
          () => {
            closeModal(modalJsonTypes[automation.appName]);
            return executeAutomation(automations, start, end, ++current);
          },
          { once: true },
        );
      } else {
        if (automation.action === 'key') {
          document.addEventListener(
            'keypress',
            () => {
              openModal(modalJsonTypes[automation.appName]);
              setTimeout(() => {
                return executeAutomation(automations, start, end, ++current);
              }, 0);
            },
            { once: true },
          );
        } else {
          document.addEventListener(
            'mousedown',
            () => {
              openModal(modalJsonTypes[automation.appName]);
              setTimeout(() => {
                return executeAutomation(automations, start, end, ++current);
              }, 0);
            },
            { once: true },
          );
        }
      }
      // document.removeEventListener('keypress', triggerModal);
    }
    if (automation.type === 'app' && automation.event === 'mousedown') {
      if (automation.name === 'dock.closeApp') {
        document.addEventListener(
          'mousedown',
          () => {
            closeModal(modalJsonTypes[automation.appName]);
            return executeAutomation(automations, start, end, ++current);
          },
          { once: true },
        );
      } else if (automation.name === 'dock.openApp') {
        const clickableElement =
          automation.action === 'clickOnElement'
            ? document.getElementById(automation.elementId)
            : document;
        if (!clickableElement) return;

        clickableElement.addEventListener(
          'mousedown',
          () => {
            setTimeout(() => {
              openModal(modalJsonTypes[automation.appName]);
              appWindowZIndex.changeActiveWindow(automation.appName);
            }, 0);

            setTimeout(() => {
              return executeAutomation(automations, start, end, ++current);
            }, 0);
          },
          { once: true },
        );
      } else {
        // dock.toggleApp
        const clickableElement =
          automation.action === 'clickOnElement'
            ? document.getElementById(automation.elementId)
            : document;
        if (!clickableElement) return;

        clickableElement.addEventListener(
          'mousedown',
          () => {
            setTimeout(() => {
              toggleModal(modalJsonTypes[automation.appName]);
              appWindowZIndex.changeActiveWindow(automation.appName);
            }, 0);

            setTimeout(() => {
              return executeAutomation(automations, start, end, current);
            }, 0);
          },
          { once: true },
        );
      }
    }
    if (automation.type === 'openGreyScreen' && automation.event === 'keypress') {
      document.addEventListener(
        'keypress',
        () => {
          setIsGreyScreen(true);
          return executeAutomation(automations, start, end, ++current);
        },
        { once: true },
      );
    }
    if (automation.type === 'openGreyScreen' && automation.event === 'mousedown') {
      document.addEventListener(
        'mousedown',
        () => {
          setIsGreyScreen(true);
          return executeAutomation(automations, start, end, ++current);
        },
        { once: true },
      );
    }

    if (automation.type === 'input' && automation.event === 'click') {
      const input = document.getElementById(automation.elementId) as HTMLInputElement;
      if (input) {
        if (input.value === '') {
          wordCount.current = automation.inputContentStart.length;
          currentWord.current = automation.inputContentStart;
          input.value = automation.inputContentStart;
        }

        if (automation.action === 'key') {
          document.addEventListener(
            'keypress',
            () => {
              currentWord.current = addOnKeyPress(
                currentWord.current,
                automation.inputContentTarget,
                wordCount.current,
              );
              input.value = currentWord.current;

              if (wordCount.current === automation.inputContentTarget.length) {
                return executeAutomation(automations, start, end, ++current);
              }
              wordCount.current = wordCount.current + 1;
              return executeAutomation(automations, start, end, current);
            },
            { once: true },
          );
        } else if (automation.action === 'delay') {
          currentWord.current = addOnKeyPress(
            currentWord.current,
            automation.inputContentTarget,
            wordCount.current,
          );
          input.value = currentWord.current;
          input.scrollTop += 10;

          setTimeout(() => {
            if (wordCount.current === automation.inputContentTarget.length) {
              document.addEventListener(
                'keypress',
                () => {
                  return executeAutomation(automations, start, end, ++current);
                },
                { once: true },
              );
            } else {
              wordCount.current = wordCount.current + 1;
              return executeAutomation(automations, start, end, current);
            }
          }, automation.delayBetweenCharacters);
        }
      } else {
        console.warn('Missing input moving automation automatically forward');
        return executeAutomation(automations, start, end, ++current);
      }
    }

    if (automation.type === 'app' && automation.event === 'change') {
      document.addEventListener(
        'keypress',
        () => {
          setBrowserWindowContent(automation.targetContent);
        },
        { once: true },
      );
    }

    if (automation.type === 'changeTab' && automation.event === 'click') {
      const element = document.getElementById(automation.targetElementId);
      document.addEventListener(
        'keypress',
        () => {
          if (element) element.click();
          return executeAutomation(automations, start, end, ++current);
        },
        { once: true },
      );
    }

    if (automation.type === 'replaceIcon' && automation.event === 'keypress') {
      const element = document.getElementById(automation.elementId);
      const eventfunc = () => {
        if (element) element.click();

        return executeAutomation(automations, start, end, ++current);
      };
      document.addEventListener('keypress', eventfunc, { once: true });
    }
    if (automation.type === 'replaceIcon' && automation.event === 'mousedown') {
      const element = document.getElementById(automation.elementId);
      document.addEventListener(
        'mousedown',
        () => {
          if (element) element.click();

          return executeAutomation(automations, start, end, ++current);
        },
        { once: true },
      );
    }

    if (automation.type === 'changePresentationMode' && automation.event === 'click') {
      console.log(automation);
      const element = document.getElementById(automation.elementId);
      console.log(element);
      document.addEventListener(
        'keypress',
        () => {
          if (element) element.click();
          return executeAutomation(automations, start, end, ++current);
        },
        { once: true },
      );
    }
    if (
      automation.type === 'changePresentationSlideForward' &&
      automation.event === 'click'
    ) {
      setTimeout(() => {
        const element = document.getElementById(automation.elementId);
        document.addEventListener(
          'keypress',
          () => {
            if (element) element.click();
            return executeAutomation(automations, start, end, ++current);
          },
          { once: true },
        );
      });
    }

    // if (automation.type === 'nextSlide')
    if (automation.name === 'lockScreen.unlock') {
      setIsScreenLocked(false);
      return executeAutomation(automations, start, end, ++current);
    }

    if (automation.name === 'elementClick' && automation.type === 'elementClick') {
      console.log('ELEMENTCLICK AUTOMATION');
      const element = document.getElementById(automation.elementId);

      if (!element) return;

      document.addEventListener(
        automation.event,
        () => {
          console.log('ELEMENT EVENT LISTENER');

          element.click();
          setTimeout(() => {
            const mailContentElement = document.getElementById(MAIL_CONTENT_ID);
            if (mailContentElement) {
              mailContentElement.scrollTo({ top: 10000, behavior: 'smooth' });
            }
          }, 5);

          return executeAutomation(automations, start, end, ++current);
        },
        { once: true },
      );
    }

    return;
  }

  return { executeAutomation };
};
