import React from 'react';
import { animated, useTransition } from 'react-spring';
import { Icon } from '../../icon/Icon';
import { ContentIconType, FileManagerType } from '../../utils/types/data';

export const Content = (props: {
  contentIcon: ContentIconType;
  data: FileManagerType;
}) => {
  const { contentIcon } = props;

  const transitions = useTransition(contentIcon, {
    from: { opacity: 0 },
    enter: { opacity: 1 },

    keys: (item) => item.contIcon, // Using the icon as a unique key
  });

  return (
    <>
      <div>
        {transitions((style, item) => (
          <animated.button key={item.contIcon} style={style}>
            <Icon
              iconSize={'large'}
              src={item.contIcon}
              text={item.contText}
              textVariant={'black'}
              truncateText={false}
            />
          </animated.button>
        ))}
      </div>
    </>
  );
};
