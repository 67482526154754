import React from 'react';

type WordTolsType = {
  tools: string[];
};

export const WordTools = ({ tools }: WordTolsType) => {
  return (
    <div className="flex justify-center bg-[#D9D9D9] rounded">
      <ul className="flex gap-3 ">
        {tools.map((oneTools, key) => {
          return (
            <li key={key} className="py-1 cursor-pointer">
              <img src={oneTools} alt="tools" />
            </li>
          );
        })}
      </ul>
    </div>
  );
};
