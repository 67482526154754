import { getJsonData } from '../utils/getJsonData';
import { Day, Desktop, WindowContents } from '../../utils/types/data';
import { LoaderFunction, LoaderFunctionArgs } from 'react-router-dom';

const extendBaseData = async (dayData: Desktop) => {
  const isExtended = 'extends' in dayData;
  if (isExtended) {
    const extendedData = await getJsonData<Desktop>(`${dayData.extends}.json`);

    for (const key in extendedData) {
      const properKey = key as keyof typeof extendedData;

      const keyExist = key in dayData;
      if (keyExist) continue;
      const onlyNewData = {
        [properKey]: extendedData[properKey],
      };

      const updatedContent = { ...dayData, ...onlyNewData };
      Object.assign(dayData, updatedContent);
    }
  }
};

const extendWindowBaseData = async (dayData: Desktop) => {
  for (const window in dayData.windowContents) {
    const properWindowKey = window as keyof WindowContents;

    const windowContent = dayData.windowContents[properWindowKey];

    if (!windowContent) continue;
    const isExtendsExist = 'extends' in windowContent;

    if (!isExtendsExist) continue;

    const extendedData = await getJsonData<typeof windowContent>(
      `${windowContent.extends}.json`,
    );

    for (const key in extendedData) {
      const properKey = key as keyof typeof extendedData;
      const keyExistInWindow = key in windowContent;
      if (keyExistInWindow) continue;
      const onlyNewData = {
        [properKey]: extendedData[properKey],
      };
      const updatedWindowContent = { ...windowContent, ...onlyNewData };
      Object.assign(windowContent, updatedWindowContent);
    }
  }
};

const getScreenData = async (params: LoaderFunctionArgs['params']) => {
  const chosenScene = params.scene;
  const day = params.day;
  const pc = params.pc;

  const planResponse = await getJsonData<Record<string, Day>>('/static/plan.json');
  // const searchClientData = await getJsonData<SearchClientData>(
  //   '/static/screens/searchClient.json',
  // );
  // const searchResultData = await getJsonData<SearchResultData>(
  //   '/static/screens/searchResult.json',
  // );
  // const emailClientData = await getJsonData<EmailClientData>(
  //   '/static/screens/emailClient.json',
  // );
  // const windowConfigData = await getJsonData<WindowConfigData>(
  //   '/static/screens/browserWindow.json',
  // );
  // const feedScreenData = await getJsonData<FeedScreenData>(
  //   '/static/screens/feedScreen.json',
  // );
  // const wordWindowData = await getJsonData<wordWindowData>(
  //   '/static/screens/wordWindow.json',
  // );

  const currentDayData =
    day && chosenScene
      ? planResponse[day].children
          .find((item) => item.name === chosenScene)
          ?.children.find((item) => item.name === pc)
      : planResponse['day1'].children[0].children[0];
  const getDayData = await getJsonData<Desktop>(
    currentDayData
      ? `/static/${currentDayData.json}.json?date=${Date.now()}`
      : `/static/desktops/pc1.json?date=${Date.now()}`,
  );

  await extendBaseData(getDayData);
  await extendWindowBaseData(getDayData);

  return {
    dayData: getDayData,
    path: currentDayData,
  };
};

export type ScreenData = Awaited<ReturnType<typeof getScreenData>>;

export const screenLoader: LoaderFunction = async ({ params }) => {
  try {
    const { dayData, path } = await getScreenData(params);

    return {
      dayData,
      path,
    };
  } catch (error) {
    if (error instanceof Error) throw new Error(error.message);
    return null;
  }
};
