import React from 'react';
import PasswordInput from './PasswordInput';
import { Header } from '../screen/Header';
import { Desktop } from '../../utils/types/data';

const LockScreen = (props: { desktopData: Desktop }) => {
  const { userName, profilePicture, icon } = props.desktopData.user;

  return (
    <>
      <div className="flex flex-col pb-4 h-dvh relative">
        <h1 className="absolute top-10 right-8 z-10 text-4xl text-gray-100 font-light">
          {userName}
        </h1>

        <img
          loading="lazy"
          src={props.desktopData.content.desktop.image}
          className="object-cover absolute inset-0 size-full opacity-80 blur-xm alt='background image'"
        />
        <Header header={props.desktopData.content.header} />
        <PasswordInput profilePicture={profilePicture} icon={icon} />
      </div>
    </>
  );
};
export default LockScreen;
