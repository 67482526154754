import * as React from 'react';
import { useState } from 'react';
import { DesktopApps } from '../../utils/types/data';
import { useLoaderData } from 'react-router-dom';
import { ScreenData } from '../../routes/loaders/screenLoader';

export type AppWindowZIndexContextProps = {
  zIndex: number;
  updateZIndex: () => void;
  changeActiveWindow: (activeWindowId: string) => void;
  changeZIndex: (newZIndex: number) => void;
  activeWindow: string;
};
export const AppWindowZIndexContext = React.createContext<AppWindowZIndexContextProps>({
  zIndex: 0,
  activeWindow: '',
  changeZIndex: () => {},
  updateZIndex: () => {},
  changeActiveWindow: () => {},
});

function getHighestZIndexSetsInData(windows: DesktopApps[]) {
  let highestZIndexSetsInData = 0;
  windows.forEach((entry) => {
    if (entry.zIndex) {
      highestZIndexSetsInData = entry.zIndex;
    }
  });
  return highestZIndexSetsInData;
}

export const AppWindowZIndexProvider = ({
  children,
  // highestZIndex,
}: {
  children: React.ReactNode;
  // highestZIndex: number;
}) => {
  const data = useLoaderData() as ScreenData;

  const [zIndex, setZIndex] = useState(
    getHighestZIndexSetsInData(data.dayData.content.dock.apps),
  );

  const [activeWindow, setActiveWindow] = useState('');

  const updateZIndex = () => {
    setZIndex(zIndex + 1);
  };

  const changeZIndex = (newZIndex: number) => {
    setZIndex(newZIndex);
  };

  const changeActiveWindow = (activeWindowId: string) => {
    // if (activeWindow === activeWindowId) {
    //   return;
    // }
    setActiveWindow(activeWindowId);

    setZIndex((prevState) => prevState + 1);
  };

  return (
    <AppWindowZIndexContext.Provider
      value={{
        zIndex,
        activeWindow,
        changeActiveWindow,
        updateZIndex,
        changeZIndex,
      }}
    >
      {children}
    </AppWindowZIndexContext.Provider>
  );
};
export const useAppWindowZIndexContext = () => {
  return React.useContext(AppWindowZIndexContext);
};
