import React from 'react';
import ImageContainer from '../ImageContainer';
import { SearchIcons } from '../../types/searchClientTypes';

const UrlBar = (props: { icons: SearchIcons }) => {
  const containerClass = `flex justify-center px-1 w-[24px] h-[24px] rounded aspect-square bg-zinc-100 rounded-l`;

  const searchBarActionButtons = [
    { src: props.icons.starIcon, class: containerClass },
    { src: props.icons.arrowLeftIcon, class: containerClass },
    { src: props.icons.arrowRightIcon, class: containerClass },
  ];

  return (
    <div
      className="w-[100%] px-1 h-18 flex gap-2.5 justify-end pt-1
     bg-white"
    >
      <div className="flex content-around gap-1">
        {searchBarActionButtons.map((action, idx) => (
          <ImageContainer
            key={idx}
            container={{ class: action.class }}
            img={{ src: action.src || '' }}
          />
        ))}
      </div>
    </div>
  );
};

export default UrlBar;
