import React, { useEffect, useState } from 'react';
import { Icon } from '../../icon/Icon';
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useRevalidator,
  useRouteLoaderData,
  useSearchParams,
} from 'react-router-dom';
import { DayRouteData, PC } from '../../utils/types/data';
import { ContextMenu, ContextMenuActionTypes } from '../contextMenu/ContextMenu';
import ClickAwayListener from 'react-click-away-listener';
import { useCurrentWindows } from '../../states/useCurrentWindows';
import { renamePcInPlan } from '../../utils/actions/renamePcInPlan';
import { addDefaultScene } from '../../utils/actions/addDefaultScene';
import { createNewFolder } from '../../utils/actions/createNewFolder';
import { duplicateScene } from '../../utils/actions/duplicateScene';
import { IconBadge } from '../../icon/IconBadge';

export type DataType = 'day' | 'scene';

type DayScreenProps = {
  type: DataType;
};

export type iconNameType = {
  curentIconname: string | undefined;
  id: number | null | undefined;
};

const DayScreen = (props: DayScreenProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useParams();
  const sceneNameFromParams = params.scene;
  const { search } = useLocation();
  const navigate = useNavigate();

  const showInvisible = searchParams.get('showInvisible');
  const { type } = props;
  const resetCurrentWindows = useCurrentWindows((state) => state.reset);
  const { dayData: routeData } = useRouteLoaderData('day') as DayRouteData;
  const revalidator = useRevalidator();
  const [selectedPc, setSelectedPc] = useState<PC | null>(null);
  const [clicked, setClicked] = useState(false);
  const [menuPosition, setMenuPosition] = useState({
    x: 0,
    y: 0,
  });
  const dayData = routeData;

  const scene = dayData.children.find((scene) => scene.name === sceneNameFromParams);
  useEffect(() => {
    setClicked(false);

    setMenuPosition({
      x: 0,
      y: 0,
    });
  }, [type]);

  const renderData = (type: DataType) => {
    console.log(menuPosition.x, menuPosition.y);

    const handleOptionClick = async (option: ContextMenuActionTypes) => {
      setSelectedPc(null);
      setClicked(false);
      if (option === 'duplicate') {
        await duplicateScene(selectedPc, params);
        revalidator.revalidate();
      }
      if (option === 'rename' && params.day && params.scene && selectedPc) {
        const newPcName = prompt('Enter new pc name', '');
        if (newPcName === null || newPcName === '') {
          console.log('User not specified name using default one');
          return;
        }
        await renamePcInPlan(selectedPc?.name, newPcName, params.day, params.scene);
        revalidator.revalidate();
      }

      if (option === 'createFolder') {
        await createNewFolder();
        revalidator.revalidate();
      }
    };

    const handleAddDefaultScene = async (sceneName: string | undefined) => {
      if (!sceneName) return;
      await addDefaultScene(sceneName);
      revalidator.revalidate();
    };

    const handleCloseContextMenu = () => {
      setClicked(false);
      setSelectedPc(null);
    };

    const handleContextMenu = (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>,
      pc?: PC,
    ) => {
      e.preventDefault();
      setClicked(true);
      setMenuPosition({
        x: e.pageX,
        y: e.pageY,
      });
      if (pc) {
        setSelectedPc(pc);
      }
    };

    console.log(dayData);

    switch (type) {
      case 'scene': {
        return scene ? (
          <div className="flex flex-col gap-3 h-svh w-fit">
            <div>
              <button className="px-4 pt-4" onClick={() => navigate(`/${params.day}`)}>
                <img src="/static/icons/back-page.svg" alt="back-page" />
              </button>
            </div>
            <div
              className={`flex flex-col flex-wrap gap-4 items-start`}
              style={{ height: 'calc(100% - 80px)' }}
            >
              {!scene.children.length && (
                <button
                  onClick={() => handleAddDefaultScene(sceneNameFromParams)}
                  className={
                    ' flex items-center gap-2 px-4 m-2 py-2 bg-green-500  rounded-2xl font-bold'
                  }
                >
                  <img src={'/static/icons/plus-circle.svg'} alt="addDefaultButton" />
                  DEFAULT JSON
                </button>
              )}

              {scene.children.map((pc, i) => {
                return pc.visible || showInvisible ? (
                  <>
                    <Link
                      onClick={() => resetCurrentWindows()}
                      key={i}
                      to={`/${params.day}/${scene.name}/${pc.name}${search}`}
                    >
                      <div key={i} className="flex flex-col gap-2 relative">
                        <Icon
                          truncateText={false}
                          textVariant={'black'}
                          src={pc.icon}
                          text={pc.name}
                          onContextMenu={(e) => handleContextMenu(e, pc)}
                        />
                        <IconBadge img={'/static/icons/eye-off.svg'} show={!pc.visible} />
                      </div>
                    </Link>
                  </>
                ) : null;
              })}
              {process.env.NODE_ENV === 'production' && clicked && (
                <ClickAwayListener onClickAway={handleCloseContextMenu}>
                  <ContextMenu
                    screenType={type}
                    position={menuPosition}
                    open={clicked}
                    onClick={handleOptionClick}
                  />
                </ClickAwayListener>
              )}
            </div>
          </div>
        ) : null;
      }

      default: {
        return (
          <div onContextMenu={(e) => handleContextMenu(e)}>
            <div className="flex flex-col gap-3 h-svh w-fit">
              <div>
                <button className="px-4 pt-4" onClick={() => navigate(`/`)}>
                  <img src="/static/icons/back-page.svg" alt="back-page" />
                </button>
              </div>
              <div
                className={`flex flex-col flex-wrap gap-4 items-start`}
                style={{ height: 'calc(100% - 80px)' }}
              >
                {dayData.children.map((scene, i) => {
                  return scene.visible || showInvisible ? (
                    <Link
                      key={i}
                      to={`/${dayData.name}/${scene.name}${search}`}
                      className={'w-fit'}
                    >
                      <div key={i} className="flex flex-col gap-2 relative">
                        <Icon
                          truncateText={false}
                          textVariant={'black'}
                          src={scene.icon}
                          text={scene.name}
                        />
                        <IconBadge
                          img={'/static/icons/eye-off.svg'}
                          show={!scene.visible}
                        />
                      </div>
                    </Link>
                  ) : null;
                })}
              </div>
            </div>
            {process.env.NODE_ENV === 'production' && clicked && (
              <ClickAwayListener onClickAway={handleCloseContextMenu}>
                <ContextMenu
                  screenType={type}
                  position={menuPosition}
                  open={clicked}
                  onClick={handleOptionClick}
                />
              </ClickAwayListener>
            )}
          </div>
        );
      }
    }
  };

  return renderData(type);
};

export default DayScreen;
