import * as React from 'react';
import { useEffect, useState } from 'react';
import { ScreenContent } from './ScreenContent';
import { ScreenDock } from './ScreenDock';
import { ModalTypesKeys, useModal } from '../../hooks/UseModal';
import { Windows } from './Windows';
import Layout from '../Layout';
import { Desktop, DesktopApps } from '../../utils/types/data';
import { useSceneContext } from '../../states/useSceneData';

import LockScreen from '../lock-screen';
import { useAppWindowZIndexContext } from './AppWindowZIndexContextProps';
import { useAutomation } from '../../hooks/UseAutomation';
import { ScreenData } from '../../routes/loaders/screenLoader';

export function getWindowsThatMayBeOpened(windows: DesktopApps[]) {
  const openedWindows: ModalTypesKeys[] = [];
  windows.forEach((window) => {
    if (window.open && window.type) {
      openedWindows.push(window.type);
    }
  });

  return openedWindows;
}

const Screen = () => {
  const { dayData: desktopData, path } = useSceneContext((state) => state);

  const [isGreyScreen, setIsGreyScreen] = useState(
    desktopData?.windowContents.greyScreen?.open ?? false,
  );
  const [isScreenLocked, setIsScreenLocked] = useState(desktopData?.isLocked ?? false);
  const [browserWindowContent, setBrowserWindowContent] = useState<
    keyof Desktop['windowContents']
  >(desktopData?.browserDefaultContent ?? 'search');
  const appWindowZIndex = useAppWindowZIndexContext();

  const headerApps = desktopData?.content.header.apps ?? [];
  const apps = desktopData?.content.dock.apps ?? [];
  const desktopApps = [...apps, ...headerApps];

  const { modal, toggleModal, closeModal, openModal, changeModalsState } = useModal(
    getWindowsThatMayBeOpened(desktopApps),
  );

  const handleChangeActiveWindow = (modalType: ModalTypesKeys) => {
    toggleModal(modalType);
    if (!modal[modalType]) {
      appWindowZIndex.changeActiveWindow(modalType);
    }
  };

  const handleAutomationReset = (data: ScreenData['dayData']) => {
    const apps = desktopData?.content.dock.apps ?? [];
    const desktopApps = [...apps];
    changeModalsState(getWindowsThatMayBeOpened(desktopApps));
  };

  const { executeAutomation } = useAutomation({
    setBrowserWindowContent,
    appWindowZIndex,
    setIsGreyScreen,
    setIsScreenLocked,
    closeModal,
    openModal,
    toggleModal,
    handleAutomationReset,
  });

  // if (desktopData) {
  //   updateSceneData(desktopData);
  // }

  useEffect(() => {
    if (desktopData)
      executeAutomation(desktopData.automations, 0, desktopData.automations.length, 0);
  }, [desktopData]);

  if (!desktopData) return null;

  if (isGreyScreen) {
    return (
      <img
        alt="Grey Screen"
        src={desktopData.windowContents.greyScreen?.src}
        className={'w-screen h-screen'}
      ></img>
    );
  }

  if (isScreenLocked) {
    return <LockScreen desktopData={desktopData} />;
  }
  return (
    <Layout
      // pcPath={data.path?.json}
      disableSelect={desktopData.disableSelect}
      desktopData={desktopData}
      handleChangeActiveWindow={handleChangeActiveWindow}
    >
      <div className="flex flex-col justify-between h-[80%] event">
        <ScreenContent
          icons={desktopData.content.desktop.icons}
          gridSize={desktopData.content.desktop.gridSize}
        />
      </div>
      <ScreenDock
        apps={desktopData.content.dock.apps}
        modal={modal}
        onClick={handleChangeActiveWindow}
      />
      <Windows
        icons={desktopData.content.desktop.window.windowIcons}
        data={{ dayData: desktopData, path }}
        modal={modal}
        closeModal={closeModal}
        browserWindowContent={browserWindowContent}
        desktopData={desktopData}
      />
    </Layout>
  );
};

export default Screen;
