import { Outlet, RouteObject } from 'react-router-dom';
import HubScreen from '../components/day-route/HubScreen';
import { getPlanData } from '../utils/actions/GetPlanData';

export async function fetchPlanData() {
  console.log('fetchPlanData');
  try {
    const days = await getPlanData(); // Put real data from API

    const data = {
      days: days?.planResponse,
    };
    console.log(data);
    return data;
  } catch (error) {
    if (error instanceof Error) throw new Error(error.message);
    return null;
  }
}

const RootRoute: RouteObject = {
  path: '/',
  id: 'root',
  element: <Outlet />,
  children: [
    {
      path: '',

      element: <HubScreen />,
      loader: fetchPlanData,
    },
  ],
};

export default RootRoute;
