type FeedSreenArticleType = {
  avatar: string;
  name: string;
  text: string;
  headerLike: string;
  headerChat: string;
  gallery?: string[];
};

export const FeedSreenArticle = ({
  avatar,
  name,
  text,
  gallery,
  headerLike,
  headerChat,
}: FeedSreenArticleType) => {
  return (
    <article className="pb-4 mb-4 border-b-2 border-[#EFE2F7]">
      <header className="flex align-center justify-between">
        <div className="flex items-center gap-2">
          <div className="w-20">
            <img src={avatar} alt="article avatar" />
          </div>
          <h2 className="text-lg font-semibold text-[#4D4D4D]">{name}</h2>
        </div>
        <div className="flex items-center gap-2">
          <div>
            <img src={headerLike} alt="like icon" />
          </div>
          <div>
            <img src={headerChat} alt="chat icon" />
          </div>
        </div>
      </header>
      <p className="pl-[5.5rem] text-sm font-medium leading-tight pr-2">{text}</p>
      <div className=" flex gap-2 pt-3 pl-[5.5rem] pr-2 ">
        {gallery &&
          gallery.map((item, key) => {
            return (
              <div key={key}>
                <img src={item} alt="foto" />
              </div>
            );
          })}
      </div>
    </article>
  );
};
