import { EmailContent } from './EmailContent';
import * as React from 'react';
import { useEffect, useState } from 'react';

import ImageContainer from '../ImageContainer';
import { EmailIcons, EmailMessage } from '../../types/emailClientTypes';
import { ReplyField } from './ReplyField';
import { EmailButton } from './EmailButton';
import { TextAreaWrapper } from '../TextAreaWrapper';
import { MAIL_CONTENT_ID } from '../../utils/constants';

export function FullEmailContent(props: {
  emailMessage: EmailMessage;
  icons: EmailIcons;
  calculatedHeight: number;
}) {
  const [activeEmailContent, setActiveEmailContent] = useState<EmailMessage>(
    props.emailMessage,
  );

  useEffect(() => {
    setActiveEmailContent((prevState) => {
      return props.emailMessage;
    });
  }, [props.emailMessage]);

  const containerClass = `flex justify-center items-center self-start px-1 w-6 h-6 rounded aspect-square bg-zinc-100`;
  const fullEmailPath = `windowContents.email.children.${0}`;

  const emailActionButtons = [
    {
      src: props.icons.starIcon,
      class: containerClass,
    },
    {
      src: props.icons.cornerUpLeftIcon,
      class: containerClass,
    },
    {
      src: props.icons.dotsVerticalIcon,
      class: containerClass,
    },
  ];

  const openReplyField = () => {
    setActiveEmailContent((prevState) => {
      return {
        ...prevState,
        replyFieldOpen: true,
      };
    });
  };

  const closeReplyField = () => {
    setActiveEmailContent((prevState) => {
      return {
        ...prevState,
        replyFieldOpen: false,
      };
    });
  };

  return (
    <div
      className="flex flex-col ml-5 w-[49%] max-md:ml-0 max-md:w-full pb-5"
      style={{ height: props.calculatedHeight }}
    >
      <div
        id={MAIL_CONTENT_ID}
        className="stable-scrollbar flex flex-col grow self-stretch px-6 pt-6 pb-9 w-full bg-white border-0 border-gray-200 border-solid max-md:px-5 max-md:max-w-full overflow-auto"
      >
        <div className="flex gap-2 justify-between  max-md:flex-wrap max-md:pr-5 max-md:max-w-full">
          <TextAreaWrapper
            Custom={
              <div className="grow text-2xl font-semibold text-black max-md:max-w-full"></div>
            }
            path={`${fullEmailPath}.subject`}
          >
            {activeEmailContent.subject}
          </TextAreaWrapper>
          {emailActionButtons.map((action) => (
            <ImageContainer
              key={action.src}
              container={{ class: action.class }}
              img={{ src: action.src || '' }}
            />
          ))}
        </div>
        <EmailContent
          message={activeEmailContent.content}
          userName={activeEmailContent.userName}
          userEmail={activeEmailContent.userEmail}
          dateTime={activeEmailContent.datetime}
        />

        <div className="flex justify-end gap-2">
          {props.emailMessage.attachmentNumber ? (
            <EmailButton id={'email-attachment'} icon={props.icons.attachmentIcon}>
              Anhänge
            </EmailButton>
          ) : null}

          <EmailButton onClick={openReplyField} id={'email-reply-button'}>
            Antwort
          </EmailButton>
        </div>

        {activeEmailContent.replyFieldOpen && (
          <ReplyField
            closeReplyField={closeReplyField}
            emailMessage={activeEmailContent}
            emailReplyIcon={props.icons.replyIcon}
          />
        )}
      </div>
    </div>
  );
}

/* Senden */

/* Frame 230 */
