import React from 'react';
import { SocialProfileDetail } from '../../../types/generalTypes';

const ProfileDetail = (props: { detailData: SocialProfileDetail }) => {
  return (
    <div className="profile-detail w-1/3 text-center">
      <img src={props.detailData.image} alt="" />
      <h5 className="text-purple-700 font-semibold mb-1">{props.detailData.name}</h5>
      <h4 className="font-bold text-social-gray mb-5">
        Follower: {props.detailData.followers}
      </h4>
      <p className="w-8/12 mx-auto text-social-gray text-sm">
        {props.detailData.description}
      </p>
    </div>
  );
};

export default ProfileDetail;
