import React from 'react';
import { useRouteError } from 'react-router-dom';

// type ErrorBoundaryProps = {};

const ErrorBoundary = () => {
  const error = useRouteError();

  return error instanceof Error ? (
    <div className="flex w-screen h-screen justify-center items-center">
      <p className="text-red-500 text-4xl">{error.message}</p>
    </div>
  ) : null;
};

export default ErrorBoundary;
