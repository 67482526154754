import * as React from 'react';

export const Divider = () => {
  return (
    <div
      className={`w-[1px] h-full mx-1 bg-divider-transparent border border-transparent`}
    ></div>
  );
};

export const DividerTransparent = () => {
  return (
    <div
      className={`w-[1px] h-full mx-1 bg-divider-transparent border border-transparent`}
    ></div>
  );
};
