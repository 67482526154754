import React from 'react';
import { ArticleResultData } from '../../utils/types/data';
import { Input } from '../browser-screen/Input';
import { ArticleManage } from '../browser-screen/ArticleManage';
import { TextAreaWrapper } from '../TextAreaWrapper';

export const ArticleResult = (props: { data: ArticleResultData }) => {
  const { hightText, logo, wallpaper, text, textSecond, category, author, date } =
    props.data.mainArticle;

  const bottomText = props.data.articleResultBottom;

  let landscape: string[] = [];
  let portrait: string[] = [];

  for (let i = 0; i <= bottomText.length - 1; i++) {
    if (i >= 3) {
      portrait = [...portrait, bottomText[i]];
    } else {
      landscape = [...landscape, bottomText[i]];
    }
  }

  const articleResultPath = `windowContents.articleResult.mainArticle`;
  const articleResultPathFooter = `windowContents.articleResult.articleResultBottom`;

  return (
    <div className="flex flex-row pb-10 bg-white">
      <main className="relative flex-initial w-2/3 pl-20 pr-10 pt-2 pb-11">
        <header>
          <div className="flex gap-4 justify-between items-center py-4">
            <TextAreaWrapper
              Custom={<h1 className="text-2xl font-semibold text-search-darkBlue"></h1>}
              path={`${articleResultPath}.hightText`}
            >
              {hightText}
            </TextAreaWrapper>
            <div>
              <img className="min-w-[140px] max-w-[150px]" src={logo} alt="img" />
            </div>
          </div>
        </header>
        <article className="pr-12">
          <div className="w-full">
            <img className="w-full h-full object-cover" src={wallpaper} alt="img" />
          </div>
          <TextAreaWrapper
            Custom={<div className="py-4 text-lg text-justify"></div>}
            isHtml
            path={`${articleResultPath}.text`}
          >
            {text}
          </TextAreaWrapper>
          <p className="pt-5">
            {textSecond}
            <div className="flex gap-2 ">
              <TextAreaWrapper
                Custom={<span className="font-bold text-search-darkBlue"></span>}
                path={`${articleResultPath}.category`}
              >
                {category}
              </TextAreaWrapper>
              <TextAreaWrapper
                Custom={
                  <span className="font-semibold px-2  border-x-2 border-black"></span>
                }
                path={`${articleResultPath}.author`}
              >
                {author}
              </TextAreaWrapper>
              <TextAreaWrapper
                Custom={<span className="font-semibold"></span>}
                path={`${articleResultPath}.date`}
              >
                {date}
              </TextAreaWrapper>
            </div>
          </p>
        </article>
      </main>
      <div className="absolute top-[32px] z-0 left-[68%] w-[2px] h-[1800px] bg-gray-200"></div>
      <aside className="flex-initial w-1/3 ">
        <header className="flex justify-end gap-1 p-1">
          {props.data.asideHeaderIcon.map((icon, key) => {
            return (
              <button
                key={key}
                className="flex justify-center px-1 w-[24px] h-[24px] rounded aspect-square bg-zinc-100 rounded-l items-center"
              >
                <img src={icon} alt="icon" />
              </button>
            );
          })}
        </header>
        <div className=" px-16 ">
          <h2 className="text-5xl leading-none font-thin text-search-blue drop-shadow-md pb-2">
            {props.data.asideHightText}
          </h2>
          <Input icon={props.data.asideInputIcon} />
          <div className="flex flex-col gap-5 pt-3 pb-3">
            {props.data.asideArticle.map((oneArticle, key) => {
              return (
                <ArticleManage
                  key={key}
                  idx={key}
                  component="ArticleResult"
                  iconSrc={oneArticle.iconSrc}
                  hightText={oneArticle.hightText}
                  text={oneArticle.text}
                  category={oneArticle.category}
                  author={oneArticle.author}
                  date={oneArticle.date}
                  allArticleIcons={props.data.allArticleIcons}
                />
              );
            })}
          </div>
        </div>
      </aside>
      <div className="flex justify-between absolute bottom-0 w-full px-2 py-1 bg-gray-200 border-t border-gray-300">
        <div className="flex gap-3">
          {landscape.map((oneLandscape, key) => (
            <TextAreaWrapper
              key={key}
              Custom={<span className="text-slate-500"></span>}
              path={`${articleResultPathFooter}.${key}`}
            >
              {oneLandscape}
            </TextAreaWrapper>
          ))}
        </div>
        <div className="flex gap-3">
          {portrait.map((onePortrait, key) => (
            <TextAreaWrapper
              key={key}
              Custom={<span className="text-slate-500"></span>}
              path={`${articleResultPathFooter}.${key + 3}`}
            >
              {onePortrait}
            </TextAreaWrapper>
          ))}
        </div>
      </div>
    </div>
  );
};
