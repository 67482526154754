import React from 'react';
import { Header } from './screen/Header';

import { Desktop } from '../utils/types/data';
import { ModalTypesKeys } from '../hooks/UseModal';

type LayoutProps = {
  children: React.ReactNode;
  desktopData: Desktop;
  pcPath?: string | undefined;
  handleChangeActiveWindow?: (modalType: ModalTypesKeys) => void;
  disableSelect?: boolean;
};

const Layout = (props: LayoutProps) => {
  const { children, desktopData, disableSelect } = props;
  const image = desktopData.content.desktop.image;
  const header = desktopData.content.header;

  return (
    <>
      <div
        className={`flex overflow-hidden flex-col pb-4 h-dvh relative ${disableSelect ? 'select-none' : ''}`}
      >
        <img
          loading="lazy"
          src={image}
          className="object-cover absolute inset-0 size-full"
        />
        <Header
          header={header}
          handleChangeActiveWindow={props.handleChangeActiveWindow}
        />
        {children}
      </div>
    </>
  );
};

export default Layout;
