import { uploadJson } from './uploadJson';
import { getPlanData } from './GetPlanData';

export const renamePcInPlan = async (
  oldName: string,
  newName: string,
  day: string,
  scene: string,
) => {
  const response = await getPlanData();
  if (!response) return null;
  const { planResponse } = response;

  const dayPlan = planResponse[day];

  // This renames everything with same name.
  const updatedScenes = dayPlan.children.map((d) => {
    if (d.name === scene) {
      return {
        ...d,
        children: d.children.map((pc) => ({
          ...pc,
          name: oldName === pc.name ? newName : pc.name,
        })),
      };
    }
    return d;
  });

  const newPlanJson = {
    ...planResponse,
    [day]: {
      ...dayPlan,
      children: updatedScenes,
    },
  };

  uploadJson('plan', newPlanJson);
};
