import { create } from 'zustand';

type GlobalStates = {
  isInEditMode: boolean;
  toggleEditMode: () => void;
};

export const useGlobalStates = create<GlobalStates>((set) => ({
  isInEditMode: false,
  toggleEditMode: () =>
    set((state) => ({
      isInEditMode: !state.isInEditMode,
    })),
}));
