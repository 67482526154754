import * as React from 'react';
import { EmailIcons, EmailMessage } from '../../types/emailClientTypes';
import dayjs from 'dayjs';
import { TextAreaWrapper } from '../TextAreaWrapper';

export const Message = (props: {
  idx: number;
  userName: string;
  emailMessage: EmailMessage;
  active: boolean;
  onClick?: (id: string) => void;
  icons: EmailIcons;
}) => {
  const { userName, datetime, subject, attachmentNumber, read, id } = props.emailMessage;
  const messagePath = `windowContents.email.children.${props.idx}`;

  // const getDateTime = () => {
  //   const today = dayjs();
  //   const emailDate = dayjs(datetime);
  //
  //   if (today.isSame(emailDate, 'day')) {
  //     return `${emailDate.format('H:mm')} Uhr`;
  //   } else if (today.isSame(emailDate, 'month') && today.isSame(emailDate, 'year')) {
  //     return emailDate.format('DD.MM');
  //   } else {
  //     return emailDate.format('DD.MM.YYYY');
  //   }
  // };

  return (
    <div
      onClick={() => props.onClick?.(id)}
      className={`flex flex-col p-3 w-full text-sm rounded text-neutral-500 cursor-pointer ${props.active ? 'bg-zinc-100 ' : 'bg-white'}`}
      id={`message-${id}`}
    >
      <div className="flex gap-5 justify-between">
        <div className="mt-2 text-base text-black whitespace-nowrap flex items-center gap-2.5">
          {!read && (
            <div
              className={
                'w-3 h-3 bg-red-500 rounded-full border-solid border-2 border-red-100'
              }
            ></div>
          )}

          <TextAreaWrapper
            Custom={
              <div
                className={`${!read ? 'font-bold' : 'font-semibold'} text-lg text-wrap`}
              ></div>
            }
            path={`${messagePath}.userName`}
          >
            {userName}
          </TextAreaWrapper>
        </div>
        <TextAreaWrapper Custom={<div></div>} path={`${messagePath}.datetime`}>
          {dayjs(datetime).format('DD.MM')}
        </TextAreaWrapper>
      </div>
      <TextAreaWrapper
        Custom={<div className="mt-2 two-line-ellipsis"></div>}
        path={`${messagePath}.subject`}
      >
        {subject}
      </TextAreaWrapper>
      {attachmentNumber !== 0 ? (
        <div className="flex gap-1 self-start p-1 mt-2 text-xs font-semibold whitespace-nowrap bg-neutral-50 rounded-[48px]">
          <img
            loading="lazy"
            src={props.icons.attachmentIcon}
            className="w-3.5 aspect-square"
          />
          <TextAreaWrapper
            Custom={<div className="grow text-ellipsis"></div>}
            path={`${messagePath}.attachmentNumber`}
          >
            {`${attachmentNumber} Anhänge`}
          </TextAreaWrapper>
        </div>
      ) : null}
    </div>
  );
};
