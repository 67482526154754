import React from 'react';
import { Link, useLoaderData, useLocation } from 'react-router-dom';
import { Icon } from '../../icon/Icon';

import { Day } from '../../utils/types/data';

const HubScreen = () => {
  const { days } = useLoaderData() as {
    days: Record<string, Day>;
  };
  const dayKeys = Object.keys(days);
  const { search } = useLocation();

  return (
    <>
      <div className={`flex flex-row flex-wrap gap-4 relative max-w-max`}>
        {dayKeys.map((dayKey, i) => {
          return (
            <Link key={i} to={`/${dayKey}${search}`} className="w-auto">
              <div className="flex flex-col gap-2">
                <Icon textVariant={'black'} src={days[dayKey].icon} text={dayKey} />
              </div>
            </Link>
          );
        })}
      </div>
    </>
  );
};

export default HubScreen;
