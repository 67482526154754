interface IconProps {
  id?: string;
  src: string;
  isActive?: boolean;
  showWhiteBackground?: boolean;
  badge?: number;
  onClick?: () => void;
}

export const IconMenu: React.FC<IconProps> = ({
  id,
  src,
  isActive,
  showWhiteBackground,
  badge,
  onClick,
}) => {
  return (
    <div
      id={id}
      onClick={onClick}
      className="relative flex flex-col flex-1 justify-center items-center self-stretch gap-1 cursor-pointer"
    >
      <div className={showWhiteBackground ? 'bg-white rounded-xl' : ''}>
        {' '}
        <img
          src={src}
          alt={src}
          className="w-12 rounded-lg border border-solid aspect-square border-white border-opacity-0"
        />
      </div>

      {isActive && (
        <div className="w-2 h-0.5 bg-white rounded-xl absolute bottom-[-4px]" />
      )}
      {badge && (
        <div className="absolute top-0 right-0 m-0.5 px-0.5 h-4 w-4 bg-red-500 rounded-full aspect-square text-white text-xs text-center font-normal ">
          {badge}
        </div>
      )}
    </div>
  );
};

{
  /* <div className="flex flex-col flex-1 justify-center items-center self-stretch">
            <img
              loading="lazy"
              srcSet="..."
              className="w-12 rounded-lg border border-solid aspect-square border-white border-opacity-0"
            />

            <div className="w-2 h-0.5 bg-white rounded-xl" />
          </div> */
}
