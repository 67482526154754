export const calculateHeight = (
  currentWindowHeight: number | undefined,
  subtractValue?: number,
  addValue?: number,
  defaultHeight = 1000,
): number => {
  if (!currentWindowHeight) return defaultHeight;

  if (addValue) return currentWindowHeight + addValue;
  else if (subtractValue) return currentWindowHeight - subtractValue;
  else return currentWindowHeight;
};
