import axios from 'axios';

export async function getImagesFromApi() {
  const response = axios.get('https://proos-api.procorp.cz/list', {
    headers: {
      'api-key': 'wfA7kzXCgixU5s4BkVzb',
    },
  });
  const images = await response;
  return images;
}
