import React, { useState } from 'react';
import { SearchIcons } from '../../types/searchClientTypes';
import { TextAreaWrapper } from '../TextAreaWrapper';

const SearchBarArea = (props: { icons: SearchIcons }) => {
  const rightList = ['Datenschutzerklärung', 'Nutzungsbedingungen', 'Einstellungen'];
  const leftList = ['Werberprogramme', 'Über Seekoo'];

  const [inputValue, setInputValue] = useState('');

  return (
    <>
      <div className="h-[80vh] w-full flex justify-center items-center">
        <div className="flex flex-col gap-6 w-3/6 rounded-3xl justify-center items-center px-20">
          <div className="flex gap- relative leading-none">
            <h1 className="text-[6rem] text-search-blue font-light font-sans">SEEKOO</h1>
            <div className="absolute top-0 right-[-82px]">
              <img src={props.icons.searchImage} alt="" className=" w-full h-full" />
            </div>
          </div>
          <div className="w-full h-full flex-col items-start justify-center flex">
            <div className="w-full relative">
              <input
                type="text"
                id="search-bar-input"
                value={inputValue}
                onChange={(event) => {
                  console.log('Change triggered');
                  setInputValue(event.target.value);
                }}
                className="w-full border rounded-full py-2 px-4 focus:outline-none shadow-md text-search-gray bg-search-darkWhite"
              />
              <img
                src={props.icons.searchIcon}
                className="absolute right-5 top-2.5 w-5"
              />
            </div>
          </div>
          <button className="shadow-md px-8 py-2 rounded-full text-search-gray font-medium bg-search-darkWhite border">
            Suchen
          </button>
        </div>
        <div className="absolute flex justify-between	px-4 py-1 bottom-0 text-search-gray w-full bg-search-lightkWhite border-t-2">
          <ul className="flex gap-5">
            {rightList.map((oneRightList, index) => {
              return (
                <TextAreaWrapper
                  key={index}
                  Custom={<li></li>}
                  // path={`${messagePath}.attachmentNumber`}
                >
                  {oneRightList}
                </TextAreaWrapper>
              );
            })}
          </ul>
          <ul className="flex gap-5">
            {leftList.map((oneLeftList, index) => {
              return (
                <TextAreaWrapper
                  key={index}
                  Custom={<li></li>}
                  // path={`${messagePath}.attachmentNumber`}
                >
                  {oneLeftList}
                </TextAreaWrapper>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default SearchBarArea;
