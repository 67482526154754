import React, { useEffect, useState } from 'react';
import { animated, useTransition } from 'react-spring';
import ImageContainer from '../ImageContainer';

import { Rnd } from 'react-rnd';

import { Coords, WindowTab } from '../../types/generalTypes';
import { DesktopApps, WindowIcons } from '../../utils/types/data';

import { useAppWindowZIndexContext } from '../screen/AppWindowZIndexContextProps';
import { resizeWindowByScreenSize } from '../../utils/functions/resizeWindowByScreenSize';
import { useCurrentWindows } from '../../states/useCurrentWindows';
import { TextAreaWrapper } from '../TextAreaWrapper';

const useModalAnimation = (modal: boolean) => {
  const transition = useTransition(modal, {
    from: {
      width: '100%',
      height: '100%',
      top: '12%',
      left: '12%',
      scale: 0,
      opacity: 0,
      transformOrigin: 'bottom',
      backdropBlur: 'blur(60px)',
    },
    enter: {
      width: '100%',
      height: '100%',
      top: '0',
      left: '0',
      scale: 1,
      opacity: 1,
      transformOrigin: 'bottom',
      backdropBlur: 'blur(60px)',
    },
    leave: {
      width: '100%',
      height: '100%',
      top: '15%',
      left: '17%',
      scale: 0,
      opacity: 0,
      transformOrigin: 'bottom',
      backdropBlur: 'blur(60px)',
    },
  });
  return { transition } as const;
};

export type WindowTypes =
  | 'mailWindow'
  | 'browserWindow'
  | 'wordWindow'
  | 'fileManagerWindow'
  | 'videoChatWindow'
  | 'graphWindow'
  | 'articleWindow'
  | 'videoPlayerWindow'
  | 'settingsWindow';

export type WindowProps = {
  windowInfo: DesktopApps | null;
  open: boolean;
  tabList: WindowTab[];
  onClose: () => void;
  children: React.ReactNode;
  icons: WindowIcons;
  type: WindowTypes;
};

const HEADER_SIZE = 23;

const Window = (props: WindowProps) => {
  const updateCurrentWindows = useCurrentWindows((state) => state.updateCurrentWindows);

  const currentWindows = useCurrentWindows((state) => state.currentWindows);
  const appWindowZIndex = useAppWindowZIndexContext();
  const [lastZIndex, setLastZIndex] = useState(props.windowInfo?.zIndex ?? 0);

  const currentScreen = props.windowInfo;

  const { onClose, open, children, icons } = props;
  const { transition } = useModalAnimation(open);
  const [isFull, setIsFull] = useState(currentScreen?.fullscreen || false);

  const [windowCoords, setWindowCoords] = useState<Coords>(
    resizeWindowByScreenSize(currentScreen?.coords),
  );

  const fullScreenWidth = window.innerWidth;

  const width = isFull
    ? fullScreenWidth
    : currentWindows[props.type]?.coords.w ?? windowCoords.w;
  const height = isFull
    ? window.innerHeight - 23
    : currentWindows[props.type]?.coords.h ?? windowCoords.h;
  const x = isFull ? 0 : currentWindows[props.type]?.coords.x ?? 0;
  const y = isFull ? HEADER_SIZE : currentWindows[props.type]?.coords.y ?? HEADER_SIZE;

  useEffect(() => {
    if (appWindowZIndex.activeWindow === props.windowInfo?.type) {
      setLastZIndex(appWindowZIndex.zIndex);
    }
  }, [appWindowZIndex.activeWindow]);

  useEffect(() => {
    if (!currentScreen || !currentScreen.type) {
      return;
    }

    updateCurrentWindows({
      [props.type]: {
        type: props.type,
        fullscreen: isFull,
        zIndex: lastZIndex,
        open: open,
        coords: {
          x: windowCoords?.x ?? 0,
          y: windowCoords?.y ?? 0,
          w: windowCoords.w,
          h: windowCoords.h,
        },
      },
    });
  }, [windowCoords, props.type, open, isFull]);

  const fullModal = () => {
    setIsFull(!isFull);
  };

  const containerClass = `flex justify-center items-center px-1 w-6 h-6 rounded aspect-square bg-white bg-opacity-30`;

  const windowActionButtons = [
    {
      src: icons.maximizeIcon,
      onClick: fullModal,
      class: containerClass + ' cursor-pointer',
    },
    {
      src: icons.minusIcon,
      onClick: undefined,
      class: containerClass,
    },
    {
      src: icons.xIcon,
      onClick: onClose,
      class: containerClass + ' cursor-pointer',
    },
  ] as const;

  const handleMouseDown = () => {
    appWindowZIndex.changeActiveWindow(props.windowInfo?.type ?? '');
    setLastZIndex(appWindowZIndex.zIndex);
  };

  return (
    <>
      {transition((style, modal) => (
        <>
          {modal ? (
            <Rnd
              bounds={'body'}
              dragHandleClassName="email-header"
              onMouseDown={handleMouseDown}
              style={{
                zIndex:
                  appWindowZIndex.activeWindow === props.windowInfo?.type ?? ''
                    ? appWindowZIndex.zIndex
                    : lastZIndex,
              }}
              size={{
                width,
                height,
              }}
              position={{
                x,
                y,
              }}
              onDragStop={(e, d) => {
                setIsFull(false);
                if (isFull) {
                  return;
                }
                setWindowCoords((prevState) => ({ ...prevState, x: d.x, y: d.y }));
              }}
              onResize={(e, direction, ref, delta, position) => {
                console.log('Resizing', {
                  w: parseInt(ref.style.width),
                  h: parseInt(ref.style.height),
                  x: position.x,
                  y: position.y,
                });
                setWindowCoords({
                  w: parseInt(ref.style.width),
                  h: parseInt(ref.style.height),
                  x: position.x,
                  y: position.y,
                });
                setIsFull(false);
              }}
            >
              <animated.div
                style={style}
                className="flex flex-col rounded-lg border border-solid border-gray-300 border-opacity-30 shadow-2xl  w-[85%]   h-5/6 absolute top-[5%] left-[5%] transform -translate-x-1/2 -translate-y-1/2 overflow-clip"
              >
                <div className="email-header cursor-move flex gap-5 justify-between px-1 w-full bg-[#a5a5a5] max-md:flex-wrap max-md:max-w-full">
                  <div className="flex gap-2">
                    <div className="flex items-center">
                      <ImageContainer
                        container={{ class: containerClass }}
                        img={{
                          src: icons.refreshIcon,
                        }}
                      />
                    </div>
                    <div className="flex mt-1 gap-0.5">
                      {props.tabList.map((tab, index) => (
                        <div
                          key={index}
                          className={`flex gap-2 w-40 ${tab.active ? 'bg-zinc-100' : 'bg-zinc-300'} text-nowrap rounded-tr-lg rounded-tl-lg px-3 flex-auto basis-[170px] border-b-2 border-zinc-300`}
                        >
                          <img src={tab.icon} className={'w-5'} alt="icon" />
                          <TextAreaWrapper
                            Custom={<div className="truncate mt-[2px]"></div>}
                            path={`content.dock.apps.2.tabList.${index}.name`}
                          >
                            {tab.name}
                          </TextAreaWrapper>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="flex gap-1 py-1">
                    {windowActionButtons.map((action) => (
                      <ImageContainer
                        key={action.src}
                        img={{ src: action.src }}
                        container={{
                          class: action.class,
                          onClick: action.onClick,
                        }}
                      />
                    ))}
                  </div>
                </div>
                <div
                  className={`w-full h-full ${props.windowInfo?.isGrayScreen ? 'bg-white' : ''}`}
                >
                  {props.windowInfo?.isGrayScreen ? (
                    <img src="/static/images/gray-screen.jpg" alt="grayscreen" />
                  ) : (
                    children
                  )}
                </div>
              </animated.div>
            </Rnd>
          ) : null}
        </>
      ))}
    </>
  );
};

export default Window;
