import React from 'react';
import { ArticleResult } from './ArticleResult';
import { ArticleResultData } from '../../utils/types/data';

export const ArticleWindow = (props: { data: ArticleResultData }) => {
  return (
    <>
      <ArticleResult data={props.data} />
    </>
  );
};
