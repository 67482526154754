import * as React from 'react';
import { EmailMenuList } from '../../types/emailClientTypes';
import { TextAreaWrapper } from '../TextAreaWrapper';

export function MenuOption(props: {
  idx: number;
  option: EmailMenuList;
  active: boolean;
  onClick: (id: string) => void;
}) {
  const { icon, name, count } = props.option;

  return (
    <div
      onClick={() => props.onClick?.(name)}
      className={`flex gap-2 justify-between p-2 text-black rounded items-center cursor-pointer  ${props.active ? 'bg-zinc-100' : 'bg-white'}`}
    >
      <img loading="lazy" src={icon} className="w-5 aspect-square min-w-5" />
      <TextAreaWrapper
        Custom={<div className="grow self-start"></div>}
        path={`windowContents.email.list.${props.idx}.name`}
      >
        {name}
      </TextAreaWrapper>
      {count > 0 && (
        <TextAreaWrapper
          Custom={
            <div className="text-white bg-[#DF4545] w-5 h-5  rounded-full text-xs flex items-center justify-center"></div>
          }
          path={`windowContents.email.list.${props.idx}.count`}
        >
          {count}
        </TextAreaWrapper>
        //  <div className="text-white bg-[#DF4545] w-5 h-5  rounded-full text-xs flex items-center justify-center">
        //     {count}
        //   </div>
      )}
    </div>
  );
}
