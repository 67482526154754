import React, { useEffect, useRef, useState } from 'react';
import { Icon, VideoChatData } from '../../types/generalTypes';
import { Participant } from './Participant';
import { SettingBar } from './SettingBar';
import { PresentationScreen } from './PresentationScreen';
import { useForceReset } from '../../states/useForceReset';

export type SettingbarIcons = {
  iconsLeft: Icon[];
  iconsCenter: Icon[];
  iconsRight: Icon[];
};

const VideoChatClient = (props: { data: VideoChatData }) => {
  const [data, setData] = useState(props.data);
  const videoRef = useRef<HTMLVideoElement>(null);
  const currentTimeRef = useRef(0);

  const forceReset = useForceReset((state) => state.forceReset);

  useEffect(() => {
    setData(props.data);
  }, [forceReset, props.data]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.currentTime = currentTimeRef.current;
    }
  }, []);

  const handleTimeUpdate = () => {
    if (videoRef.current) {
      currentTimeRef.current = videoRef.current.currentTime;
    }
  };
  const presentationMode = data.presentationMode;

  const settingBarIcons: SettingbarIcons = {
    iconsLeft: data.icons.settingBar.left,
    iconsCenter: data.icons.settingBar.center,
    iconsRight: data.icons.settingBar.right,
  };

  const handlePresentationMode = () => {
    setData((prevState) => ({ ...prevState, presentationMode: true }));
  };

  return (
    <div className={'w-full bg-white'} style={{ height: 'calc(100% - 54px)' }}>
      {presentationMode ? (
        <PresentationScreen
          grayScreen={data.grayScreen}
          multyCall={data.multyCall}
          presenterSettings={data.presenterSettings}
          presenterWindow={data.participantList[0]}
          presentationImages={data.presentationImages}
          placeholderUserImage={data.placeholderUserImage}
          videoRef={videoRef}
          currentTimeRef={currentTimeRef}
          handleTimeUpdate={handleTimeUpdate}
        />
      ) : (
        <div
          // 'w-full  grid grid-cols-[repeat(auto-fit,minmax(350px,1fr))] gap-1 bg-zinc-900'

          className={
            data.multyCall
              ? 'w-full h-full grid grid-cols-1 grid-rows-1 gap-1 bg-black'
              : 'w-full h-full grid grid-cols-[repeat(4,1fr)] grid-rows-[repeat(3,minmax(250px,1fr))] gap-1  bg-black overflow-y-auto'
          }
        >
          {data.participantList.map((participant, idx) => {
            return (
              <Participant
                grayScreen={data.grayScreen}
                multyCall={data.multyCall}
                key={idx}
                name={participant.name}
                placeholderUserImage={data.placeholderUserImage}
                srcType={participant.type}
                src={participant.src}
                currentTimeRef={currentTimeRef}
                handleTimeUpdate={handleTimeUpdate}
              />
            );
          })}
        </div>
      )}
      <SettingBar
        presentationImagesLength={data.presentationImages.length}
        isPresentationMode={presentationMode}
        settingBarIcons={settingBarIcons}
        onSwitchPresentationMode={handlePresentationMode}
        type={'videoChat'}
      />
    </div>
  );
};
export default VideoChatClient;
