import { Coords } from '../../types/generalTypes';

// we measure that every window has 1920 x 1080 but this is not true. Can be smaller we must calculate size change

const SCREEN_WIDTH_DEFAULT = 1920;
const SCREEN_HEIGHT_DEFAULT = 1080;

function calcWidthResizeChange(v2: number, v1: number) {
  return (v2 - v1) / v1 + 1;
}

export function resizeWindowByScreenSize(coords?: Coords): Coords {
  const screenWidthChange = calcWidthResizeChange(
    window.innerWidth,
    SCREEN_WIDTH_DEFAULT,
  );
  const screenHeightChange = calcWidthResizeChange(
    window.innerHeight,
    SCREEN_HEIGHT_DEFAULT,
  );
  if (!coords) {
    return {
      h: window.innerHeight,
      w: window.innerWidth,
      x: 0,
      y: 0,
    };
  }
  if (
    coords.w + coords.x > window.innerWidth ||
    coords.h + coords.y > window.innerHeight
  ) {
    const newCoords = {
      w: Math.floor(coords.w * screenWidthChange),
      x: Math.floor(coords.x * screenWidthChange),
      y: Math.floor(coords.y * screenHeightChange),
      h: Math.floor(coords.h * screenHeightChange),
    };
    return newCoords;
  }
  return coords;
}
