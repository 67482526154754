import { LoaderFunction, Outlet, RouteObject } from 'react-router-dom';

import ErrorBoundary from '../components/ErrorBoundary';
import DayScreen from '../components/day-route/DayScreen';
import { getPlanData } from '../utils/actions/GetPlanData';

export const planRouter: LoaderFunction = async ({ params }) => {
  const day = params.day;

  try {
    const response = await getPlanData();
    if (!response) return null;
    const { planResponse } = response;
    // Put real data from API

    if (!day || !planResponse[day]) {
      throw new Error(`Cannot find ${day}`);
    }

    return {
      dayData: planResponse[day],
    };
  } catch (error) {
    if (error instanceof Error) throw new Error(error.message);
    return null;
  }
};

const DayRoute: RouteObject = {
  path: '/:day',
  id: 'day',
  loader: planRouter,
  element: <Outlet />,
  errorElement: <ErrorBoundary />,

  children: [
    {
      path: '',
      element: <DayScreen type="day" />,
    },
    {
      path: ':scene',
      element: <DayScreen type="scene" />,
    },
  ],
};

export default DayRoute;
