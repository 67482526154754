import React from 'react';
import { WordWindowData } from '../../types/generalTypes';
import { WordNav } from './WordNav';
import { WordTools } from './WordTools';
import { WordToolsBottom } from './WordToolsBottom';
import { Paper } from './Paper';

export const WordWindow = (props: { data: WordWindowData }) => {
  const wordData = props.data;
  const { tools } = wordData;
  const { list, inputIcon, arrowLeft, arrowRight } = wordData.topNav;
  const { dropDown } = wordData.toolsBottom;
  const { iconsSecond, iconsMain } = wordData.toolsBottom.icons;
  const { hight, text } = wordData.paper;

  return (
    <div className=" overflow-y-auto bg-word-background w-full h-full ">
      <header className="flex flex-col gap-1 p-1 w-full bg-word-background absolute">
        <WordNav
          list={list}
          navIcon={inputIcon}
          arrowLeft={arrowLeft}
          arrowRight={arrowRight}
        />
        <WordTools tools={tools} />
        <WordToolsBottom
          dropDown={dropDown}
          toolsMain={iconsMain}
          toolsSecond={iconsSecond}
        />
      </header>
      <main className="py-[7rem] px-[3rem] ">
        <Paper hight={hight} text={text} />
      </main>
    </div>
  );
};
