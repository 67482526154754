import React from 'react';
import { DropDownType } from '../../types/generalTypes';
// import triangeImg from '../../../public/static/icons/triangle.svg'

type WordToolsBottomType = {
  dropDown: DropDownType[];
  toolsMain: string[];
  toolsSecond: string[];
};

export const WordToolsBottom = ({
  dropDown,
  toolsMain,
  toolsSecond,
}: WordToolsBottomType) => {
  return (
    <div className="w-full flex gap-4 bg-word-gray rounded overflow-clip">
      <form className="flex-[40%] flex items-center gap-2">
        {dropDown.map((oneDropDown, key) => {
          const { options } = oneDropDown;
          return (
            <select
              key={key}
              className="flex-1 text-center  cursor-pointer rounded-md border-[1px] border-slate-200"
            >
              {options.map((oneOption, otionKey) => {
                return <option key={otionKey}>{oneOption}</option>;
              })}
            </select>
          );
        })}
      </form>
      <ul className="flex-[20%]  flex items-center justify-center">
        {toolsMain.map((OneIconMain, key) => {
          return (
            <li key={key} className="flex w-[30px] justify-center cursor-pointer">
              <img src={OneIconMain} alt="icon" />
            </li>
          );
        })}
      </ul>
      <ul className="flex-[40%] flex items-center">
        {toolsSecond.map((OneIconSecond, key) => {
          return (
            <li key={key} className="w-[30px]">
              <img className="cursor-pointer" src={OneIconSecond} alt="icon" />
            </li>
          );
        })}
      </ul>
    </div>
  );
};
