import * as React from 'react';

import 'dayjs/locale/de';
import { EmailMessage } from '../../types/emailClientTypes';
import { TextAreaWrapper } from '../TextAreaWrapper';

export const EmailContent = (props: {
  message: EmailMessage['content'];
  userName: EmailMessage['userName'];
  userEmail: EmailMessage['userEmail'];
  dateTime: EmailMessage['datetime'];
}) => {
  const getNameInitials = (name: string) => {
    const words = name.split(' ');
    const initials = words.map((word) => word.charAt(0)).join('');
    return initials.toUpperCase();
  };
  const emailContentPath = `windowContents.email.children.${0}`;

  return (
    <>
      <div className="flex gap-2 justify-between mt-4 whitespace-nowrap max-md:flex-wrap max-md:max-w-full">
        <div className="flex flex-col justify-center text-sm font-semibold text-center aspect-square text-neutral-500">
          <div className="flex justify-center items-center  h-10 w-10 rounded-full  bg-[linear-gradient(180deg,#F5F5F5_0%,#DEDEDE_100%)]">
            {getNameInitials(props.userName)}
          </div>
        </div>
        <div className="flex flex-col flex-1 justify-center   max-md:max-w-full">
          <div className="flex  max-md:flex-wrap max-md:max-w-full">
            <TextAreaWrapper
              Custom={<div className="grow text-lg font-semibold text-black"></div>}
              path={`${emailContentPath}.userName`}
            >
              {props.userName}
            </TextAreaWrapper>
            {/* <div className="text-sm  text-neutral-500">
              {dayjs(props.dateTime).locale('de').format('DD. MMMM YYYY, H:mm [Uhr]')}
            </div> */}
          </div>
          {/* <div className="text-sm text-neutral-500 max-md:max-w-full">*/}
          {/*  {props.userEmail}*/}
          {/*</div> */}
        </div>
      </div>
      {/* <div dangerouslySetInnerHTML={{ __html: props.message }} /> */}
      <TextAreaWrapper Custom={<div />} isHtml path={`${emailContentPath}.content`}>
        {props.message}
      </TextAreaWrapper>
      {/* <div dangerouslySetInnerHTML={{ __html: props.message }} /> */}
    </>
  );
};
