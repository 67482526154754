import { useEffect, useState } from 'react';
import { Desktop, PC } from '../../utils/types/data';
import { getImagesFromApi } from '../../utils/actions/getImagesFromApi';
import { useSceneContext } from '../../states/useSceneData';

export type Image = {
  name: string;
  content: string;
};
type ImageResponse = {
  original: string;
  thumbnail: string;
};

type SettingsWindowProps = {
  data: {
    dayData: Desktop;
    path: PC | undefined;
  };
};

const useImages = () => {
  const [imageList, setImageList] = useState<ImageResponse[] | []>([]);

  useEffect(() => {
    getImageList();
  }, []);

  const getImageList = async () => {
    try {
      const images = await getImagesFromApi();
      setImageList(images.data);
    } catch (e) {
      console.error(e);
    }
  };

  return { imageList };
};

export const SettingsWindow = ({ data }: SettingsWindowProps) => {
  const { path } = data;
  const { imageList } = useImages();
  const updateDataScreen = useSceneContext((state) => state.updateSceneBackground);

  function setNewBackgroundImage(selectedImage: ImageResponse) {
    if (!path) return;
    updateDataScreen(selectedImage.original);
  }

  const changeBackgroundImage = async (index: number) => {
    console.log('clicked');
    const selectedImage = imageList[index];
    setNewBackgroundImage(selectedImage);
  };

  return (
    <div className={'w-full h-full bg-white'}>
      {imageList && imageList.length ? (
        <div className={'flex flex-wrap gap-1'}>
          {imageList.map((image, i) => (
            <button key={i} onClick={() => changeBackgroundImage(i)}>
              <img
                src={image.thumbnail}
                alt="image"
                className={'w-40 h-40 border  border-black'}
              />
            </button>
          ))}
        </div>
      ) : (
        <div className={'text-xl text-center'}>No images found</div>
      )}
    </div>
  );
};
