import React from 'react';

const HeaderNavItem = (props: {
  icon: string;
  title: string;
  count: number | undefined;
}) => {
  const { icon, title, count } = props;

  return (
    <div className="bg-zinc-100 text-social-gray flex flex-col rounded-lg w-32 justify-center items-center font-bold relative">
      <img src={icon} alt="" />
      <div>{title}</div>
      {count && (
        <div className="absolute top-1 right-1 w-5 h-5 bg-purple-400 rounded-full text-black flex justify-center items-center text-xs">
          {count}
        </div>
      )}
    </div>
  );
};

export default HeaderNavItem;
