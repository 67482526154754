import { Message } from './Message';
import * as React from 'react';
import { useState } from 'react';

import { MenuOption } from './MenuOption';
import { FullEmailContent } from './FullEmailContent';
import { EmailClientData } from '../../types/generalTypes';

import { calculateHeight } from '../../utils/functions/calculateHeight';
import { useCurrentWindows } from '../../states/useCurrentWindows';
import { TextAreaWrapper } from '../TextAreaWrapper';

export const EmailClient = (props: { data: EmailClientData }) => {
  const [activeMenuTab, setActiveMenuTab] = useState(props.data.list[0].name);
  const [activeEmailId, setActiveEmailId] = useState(props.data.children[0].id);

  const currentWindows = useCurrentWindows((state) => state.currentWindows);

  const { list, children, icons } = props.data;

  const activeEmail = props.data.children.find((child) => child.id === activeEmailId);

  return (
    <div className="flex gap-5 max-md:flex-col max-md:gap-0 overflow-y-auto bg-white w-full h-full">
      <div
        className="flex flex-col w-[18%] max-md:ml-0 max-md:w-full"
        style={{
          height: currentWindows.mailWindow?.fullscreen
            ? '100%'
            : calculateHeight(currentWindows.mailWindow?.coords.h),
        }}
      >
        <div className="flex flex-col grow self-stretch font-semibold whitespace-nowrap h-full overflow-auto">
          <div className="flex flex-col justify-center p-4 w-full text-xl text-black bg-white border-b border-solid border-b-gray-200">
            <div className="flex gap-2 justify-between">
              <img loading="lazy" src={icons.mailIcon} className="w-12 aspect-square" />
              <div className="grow my-auto flex flex-col items-center">
                <TextAreaWrapper Custom={<span></span>}>Seekoo</TextAreaWrapper>
                <TextAreaWrapper Custom={<span></span>}>Mail</TextAreaWrapper>
              </div>
            </div>
          </div>
          <div className="flex flex-col pl-4 pr-1 pt-4 pb-12 w-full text-sm bg-white text-neutral-500 min-w-44 overflow-y-auto">
            {list.map((option, idx) => {
              return (
                <MenuOption
                  key={idx}
                  idx={idx}
                  option={option}
                  active={activeMenuTab === option.name}
                  onClick={(name: string) => {
                    setActiveMenuTab(name);
                  }}
                />
              );
            })}
          </div>
        </div>
      </div>
      <div
        className="flex flex-col ml-5 w-[33%] max-md:ml-0 max-md:w-full"
        style={{
          height: currentWindows.mailWindow?.fullscreen
            ? '100%'
            : calculateHeight(currentWindows.mailWindow?.coords.h),
        }}
      >
        <div className="flex flex-col grow self-stretch px-2 pb-10 pt-2 mx-auto w-full bg-white border-r border-l border-solid border-x-gray-200 h-full overflow-auto">
          {children.map((emailMessage, idx) => (
            <Message
              userName={emailMessage.userName}
              key={idx}
              idx={idx}
              emailMessage={emailMessage}
              icons={icons}
              active={activeEmailId === emailMessage.id}
              onClick={(id: string) => {
                setActiveEmailId(id);
              }}
            />
          ))}
        </div>
      </div>
      {activeEmail && (
        <FullEmailContent
          calculatedHeight={calculateHeight(currentWindows.mailWindow?.coords.h)}
          emailMessage={activeEmail}
          icons={icons}
        />
      )}
    </div>
  );
};
