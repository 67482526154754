import React from 'react';

type EmailReplyButtonProps = {
  children: React.ReactNode;
  position?: 'left' | 'center' | 'right';
  onClick?: () => void;
  id?: string;
  icon?: string;
};

export const EmailButton = ({
  children,
  position,
  onClick,
  id,
  icon,
}: EmailReplyButtonProps) => {
  const positionClasses = {
    left: 'justify-start',
    center: 'justify-center',
    right: 'justify-end',
  };

  const positionClass = position ? positionClasses[position] : 'justify-start';

  return (
    <div className={`flex ${positionClass} `}>
      <button
        id={id}
        onClick={onClick}
        className={
          'w-fit px-2 py-0.5 font-semibold bg-gradient-to-b from-gray-100 to-gray-300 rounded-lg text-black text-opacity-50 flex items-center gap-1'
        }
      >
        <img src={icon} alt="" />
        {children}
      </button>
    </div>
  );
};
