import { ModalTypes } from '../hooks/UseModal';
import { DesktopApps } from '../utils/types/data';
import { create } from 'zustand';

type CurrentWindows = {
  reset: () => void;
  currentWindows: Partial<Record<keyof ModalTypes, DesktopApps>>;
  updateCurrentWindows: (value: Partial<Record<keyof ModalTypes, DesktopApps>>) => void;
};

export const useCurrentWindows = create<CurrentWindows>()((set) => ({
  currentWindows: {},
  updateCurrentWindows: (value) =>
    set(
      (state) => (
        console.log(state),
        {
          currentWindows: {
            ...state.currentWindows,
            ...value,
          },
        }
      ),
    ),
  reset: () => {
    set(() => ({ currentWindows: {} }));
  },
}));
