import React from 'react';

const PasswordInput = (props: { profilePicture: string; icon: string }) => {
  return (
    <div className="w-2/6 h-20 flex  z-10 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2  rounded-xl justify-center">
      <div className="flex w-[96%] flex-col gap-4 items-center justify-center">
        <img
          src={props.profilePicture}
          className="rounded-xl text-center"
          alt="profile picture"
        />
        <div className="relative w-full p-3 bg-neutral-100 rounded-full">
          <input
            id="lock-screen-password"
            type="password"
            placeholder="Password"
            className="w-full relative rounded-xl bg-neutral-100 focus:outline-none text-center text-[1.3rem] font-semibold border border-zinc-300 placeholder-gray-500"
          />
          <div className="absolute top-[13px] right-[16px] p-2 border-l border-zinc-300">
            <img src={props.icon} alt="icon" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default PasswordInput;
