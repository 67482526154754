import React from 'react';

export const IconBadge = (props: { img: string; show: boolean }) => {
  const { img, show } = props;

  return show ? (
    <div className="absolute top-0 right-0 m-0.5 px-0.5  rounded-full aspect-square text-white text-xs text-center font-normal">
      <img src={img} alt="badge" />
    </div>
  ) : null;
};
