import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { animated, useTransition } from 'react-spring';

export const Participant = (props: {
  grayScreen: string;
  multyCall: boolean;
  name: string;
  src: string | undefined;
  placeholderUserImage: string;
  showAnimation?: boolean;
  srcType: 'image' | 'video';
  currentTimeRef: MutableRefObject<number>;
  handleTimeUpdate?: () => void;
}) => {
  const {
    grayScreen,
    multyCall,
    name,
    src,
    placeholderUserImage,
    srcType,
    currentTimeRef,
  } = props;
  const [videoUrl, setVideoUrl] = useState('');

  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.currentTime = currentTimeRef.current;
    }
  }, [currentTimeRef.current]);

  const [show] = useState(true);

  const transitions = useTransition(show, {
    from: { opacity: 0, transform: 'translateX(-300%)' },
    enter: { opacity: 1, transform: 'translateX(0%)' },
    leave: { opacity: 0, transform: 'translateX(0%)' },
    delay: 1,
  });
  useEffect(() => {
    const loadVideo = async () => {
      if (!src) return;
      const response = await fetch(src);
      const data = await response.blob();
      const video = URL.createObjectURL(data);
      console.log(video);
      setVideoUrl(video);
    };
    loadVideo();
  }, []);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.load();
    }
  }, [videoUrl]);

  return (
    <>
      {transitions((style, item) =>
        item ? (
          <animated.div
            style={props.showAnimation ? style : undefined}
            className={
              'w-full h-full flex items-end justify-start relative overflow-hidden'
            }
          >
            {src ? (
              srcType === 'video' ? (
                <div className="w-full h-full flex items-end justify-start overflow-hidden relative ">
                  <video
                    muted
                    loop
                    ref={videoRef}
                    preload={'auto'}
                    onCanPlayThrough={(event) => {
                      event.currentTarget.play();
                    }}
                    onEnded={() => {
                      console.log(`VideoEnds ${name}`);
                    }}
                    onSuspend={() => {
                      console.log(`video suspended ${name}`);
                    }}
                    onStalled={() => {
                      console.log(`video staled ${name}`);
                    }}
                    onWaiting={() => {
                      console.log(`Video waiting ${name}`);
                    }}
                    // onProgress={}
                    className="w-full h-full object-cover"
                  >
                    <source src={videoUrl} type={'video/mp4'} />
                  </video>
                </div>
              ) : (
                <img src={src} alt="participant image" className={'w-full h-full'} />
              )
            ) : (
              <div className={'w-full h-full flex items-center justify-center'}>
                <img
                  src={multyCall ? grayScreen : placeholderUserImage}
                  className={` ${multyCall ? ' h-full  aspect-video' : 'w-2/3 h-5/6 object-contain'} `}
                />
              </div>
            )}
            <p className={'absolute text-white bg-black/30 rounded-xl px-2 m-2'}>
              {name}
            </p>
          </animated.div>
        ) : null,
      )}
    </>
  );
};
