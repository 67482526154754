import React, { JSX, useEffect, useState } from 'react';

import { Desktop } from '../utils/types/data';
import { useSceneContext } from '../states/useSceneData';
import { useGlobalStates } from '../states/useGlobalStates';

type TextAreaWrapperProps = {
  children: string | number;
  path?: string;
  isHtml?: boolean;
  Custom?: React.ReactElement<{
    children: React.ReactNode;
    onClick: React.ReactEventHandler;
  }>;
};

export const TextAreaWrapper = ({
  children,
  isHtml,
  path,
  Custom,
}: TextAreaWrapperProps): JSX.Element => {
  const updateSceneData = useSceneContext((state) => state.updateSceneData);
  const sceneData = useSceneContext((state) => state.dayData);
  const isInEditMode = useGlobalStates((state) => state.isInEditMode);
  const [editing, setEditing] = useState(false);
  const [textAreaValue, setTextAreaValue] = useState(children ?? '');
  const cols = String(children).length;
  const dynamicRows = String(textAreaValue).length / cols;
  const rows = dynamicRows || 1;

  const deepSet = <T,>(obj: T[] | T, path: string, val: string | number) => {
    path = path.replaceAll('[', '.[');
    const keys = path.split('.');

    for (let i = 0; i < keys.length; i++) {
      let currentKey = keys[i];
      let nextKey = keys[i + 1];
      if (currentKey.includes('[')) {
        // @ts-expect-error ts-expect-error
        currentKey = parseInt(currentKey.substring(1, currentKey.length - 1));
      }
      if (nextKey && nextKey.includes('[')) {
        // @ts-expect-error ts-expect-error
        nextKey = parseInt(nextKey.substring(1, nextKey.length - 1));
      }

      if (typeof nextKey !== 'undefined') {
        // @ts-expect-error ts-expect-error
        obj[currentKey] = obj[currentKey] ? obj[currentKey] : isNaN(nextKey) ? {} : [];
      } else {
        // @ts-expect-error ts-expect-error
        obj[currentKey] = val;
      }
      // @ts-expect-error ts-expect-error
      obj = obj[currentKey];
    }
  };

  useEffect(() => {
    if (!isInEditMode) setEditing(false);
  }, [isInEditMode]);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextAreaValue(event.target.value);
  };

  const handleEnterEditMode = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!isInEditMode) return;
    if (e.metaKey || e.ctrlKey) {
      setEditing(true);
    }
  };

  const handleCancelEdit = () => {
    setEditing(false);
  };

  const elementProps = isHtml
    ? { dangerouslySetInnerHTML: { __html: children }, onClick: handleEnterEditMode }
    : {
        children: children,
        onClick: handleEnterEditMode,
      };

  // if (isHtml) {
  //   console.log(Custom, isHtml);
  // }

  const handleSave = () => {
    if (!path) throw new Error('MISSING PATH');
    if (!sceneData) return;
    const updatedSceneData: Desktop = { ...sceneData };
    deepSet(updatedSceneData, path, textAreaValue);
    updateSceneData(updatedSceneData);

    setEditing(false);
  };

  const Element = !Custom ? (
    <span onClick={handleEnterEditMode}>{children}</span>
  ) : (
    React.cloneElement(Custom, elementProps)
  );
  return editing ? (
    <div className={'flex z-50'}>
      <textarea
        cols={cols}
        rows={rows}
        defaultValue={children}
        onChange={(event) => handleChange(event)}
      />
      <div className={'flex flex-col gap-0.5'}>
        <button
          className={'bg-white w-5 h-5 border-2 border-black rounded'}
          onClick={handleSave}
        >
          <img src="/static/icons/check-icon.svg" alt="" />
        </button>
        <button
          className={'bg-white w-5 h-5 border-2 border-black rounded'}
          onClick={handleCancelEdit}
        >
          <img src="/static/icons/x-red.svg" alt="" />
        </button>
      </div>
    </div>
  ) : (
    Element
  );
};
