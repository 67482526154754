import { Desktop, PC } from '../types/data';
import { Params } from 'react-router-dom';
import { getJsonData } from '../../routes/utils/getJsonData';
import { updatePlanWithNewPc } from './updatePlanWithNewPc';
import { uploadJson } from './uploadJson';

export async function duplicateScene(selectedPc: null | PC, params: Params) {
  // we need update add new json and update plan.json.
  if (!selectedPc) {
    console.error('NOT SELECTED PC');
    return;
  }
  const path = `${selectedPc.json}`;

  try {
    const pcData = await getJsonData<Desktop>(`${path}.json`);
    console.log(pcData);
    let pcName = prompt('Enter new pc name', `${selectedPc.name}_copy`);

    if (pcName === null || pcName === '') {
      console.log('User not specified name using default one');
      pcName = `${selectedPc.name}_copy`;
    }

    // remove from path everything from last slash
    const pathWithoutLastSlash = path.substring(0, path.lastIndexOf('/'));

    const jsonPath = `${pathWithoutLastSlash}/${pcName}`;

    uploadJson(jsonPath, pcData);

    // dangerous area we don't want to screw this bacause this may broke everything save new plan
    if (!params.day || !params.scene) {
      return;
    }
    await updatePlanWithNewPc(pcName, jsonPath, params.day, params.scene);
  } catch (e) {
    console.log(e);
  }
}
