import { PC } from '../types/data';

export function createPc(newPcName: string, jsonPath: string): PC {
  return {
    name: newPcName,
    json: `${jsonPath}`,
    icon: '/static/icons/DesktopIcons6.svg',
    visible: 1,
  };
}
