import { Button, Input, Modal } from 'rsuite';
import { useState } from 'react';

type LoginModalProps = {
  onSubmit: () => void;
};

export const LoginModal = ({ onSubmit }: LoginModalProps) => {
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');

  const correctCredentials = {
    name: 'sirena',
    password: 'CityOfNight',
  };

  const [open, setOpen] = useState(true);
  const [hasError, setHasError] = useState(false);

  const handleClose = () => setOpen(false);

  const handleSubmit = () => {
    if (name === correctCredentials.name && password === correctCredentials.password) {
      localStorage.setItem('isLogged', 'true');
      handleClose();
      onSubmit();
      setHasError(false);
    } else {
      setHasError(true);
    }
  };

  return (
    <Modal backdrop={'static'} keyboard={false} open={open} onClose={handleClose}>
      <div className={'text-center'}>Login</div>
      <Modal.Body>
        <div className={'flex flex-col gap-4'}>
          <Input placeholder={'Name'} onChange={(e) => setName(e)} />
          <Input
            type={'password'}
            placeholder={'Password'}
            onChange={(e) => setPassword(e)}
          />
        </div>
      </Modal.Body>
      <div className={'flex justify-between items-center'}>
        {hasError && <p className={'text-red-600'}>Wrong credentials</p>}
        <Button onClick={handleSubmit} appearance={'ghost'} className={'ml-auto'}>
          Submit
        </Button>
      </div>
    </Modal>
  );
};
