import { VideoPlayerData } from '../../utils/types/data';
import { SettingBar } from '../video-chat-screen/SettingBar';
import { SettingbarIcons } from '../video-chat-screen/VideoChatClient';

export const VideoPlayerWindow = (props: { data: VideoPlayerData }) => {
  console.log(props.data);
  const settingBarIcons: SettingbarIcons = {
    iconsLeft: props.data.icons.settingBar.left,
    iconsCenter: props.data.icons.settingBar.center,
    iconsRight: props.data.icons.settingBar.right,
  };

  return (
    <div className={'w-full h-full bg-white'}>
      <img src={props.data.src} alt="" />
      <SettingBar settingBarIcons={settingBarIcons} type={'videoPlayer'} />
    </div>
  );
};
