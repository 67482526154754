import * as React from 'react';
import { Fragment, useState } from 'react';
import { IconHeader } from '../../icon/IconHeader';
import { Desktop, DesktopApps, DesktopHeaderSection } from '../../utils/types/data';
import { Divider, DividerTransparent } from '../Divider';
import axios from 'axios';
import { CustomProvider, DatePicker } from 'rsuite';
import deDe from 'rsuite/locales/de_DE';
import dayjs from 'dayjs';
import { useCurrentWindows } from '../../states/useCurrentWindows';
import { ModalTypes, ModalTypesKeys } from '../../hooks/UseModal';

import { useGlobalStates } from '../../states/useGlobalStates';
import { useSceneContext } from '../../states/useSceneData';

type HeaderProps = {
  header: {
    left?: DesktopHeaderSection;
    center?: DesktopHeaderSection;
    right?: DesktopHeaderSection;
  };
  handleChangeActiveWindow?: (modalType: ModalTypesKeys) => void;
};

export type HeaderContextMenuActions = {
  saveData: () => void;
  resetCoords: () => void;
  toggleOpenSettings: () => void;
  toggleEditMode: () => void;
};

export const Header = (props: HeaderProps) => {
  const [header, setHeader] = useState(props.header);
  const [isTimePickerOpen, setIsTimePickerOpen] = useState<boolean>(false);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState<boolean>(false);
  const sceneData = useSceneContext((state) => state.dayData);
  const path = useSceneContext((state) => state.path?.json);

  const updateCurrentWindows = useCurrentWindows((state) => state.updateCurrentWindows);
  const toggleEditMode = useGlobalStates((state) => state.toggleEditMode);
  const currentWindows = useCurrentWindows((state) => state.currentWindows);

  const [clicked, setClicked] = useState(false);
  const postNewData = async (updatedData: Desktop, path: string) => {
    try {
      await axios.post(
        `https://proos-api.procorp.cz/json`,
        {
          path: path,
          json: updatedData,
        },
        {
          headers: {
            'api-key': 'wfA7kzXCgixU5s4BkVzb',
          },
        },
      );
    } catch (e) {
      console.log('ERROR:', e);
    }
  };

  const handleSaveData = () => {
    if (!sceneData || Object.keys(currentWindows).length === 0) return;

    const dataToUpdate = { ...sceneData };
    dataToUpdate.content.header = { ...header };

    dataToUpdate.content.dock.apps = dataToUpdate.content.dock.apps.map((window) => {
      if (!window.type) {
        return window;
      }
      const matchingWindow = currentWindows[window.type];

      if (matchingWindow) {
        return {
          ...window,
          ...matchingWindow,
        };
      }

      return window;
    });

    if (!path) {
      console.error('MISSING PC PATH!!!!');
      return;
    }

    postNewData(sceneData, path);
  };

  const resetCoords = () => {
    const updatedWindows = { ...currentWindows };
    for (const windowKey in updatedWindows) {
      const key = windowKey as keyof Partial<Record<keyof ModalTypes, DesktopApps>>;
      const windows = updatedWindows[key];
      if (!windows) continue;
      windows.coords = {
        x: 0,
        y: 23,
        w: window.innerWidth,
        h: window.innerHeight - 23,
      };
    }

    console.log('this is updatedWindows', updatedWindows);

    updateCurrentWindows(updatedWindows);
  };

  const toggleDateTimePickersOpen = (action: string | undefined) => {
    if (!action) return;

    switch (action) {
      case 'changeHeaderTime':
        setIsTimePickerOpen((prevState) => !prevState);
        break;
      case 'changeHeaderDate':
        setIsDatePickerOpen((prevState) => !prevState);
        break;
    }
  };

  const handleSelectTime = (
    dateTime: Date,
    iconName: 'changeHeaderDate' | 'changeHeaderTime',
  ) => {
    dayjs.locale('de');

    iconName === 'changeHeaderDate'
      ? setIsDatePickerOpen(false)
      : setIsTimePickerOpen(false);

    if (!header.center) return;
    const indexOfchangeHeaderDateTime = header.center.icons.findIndex(
      (icon) => icon.action === iconName,
    );

    if (indexOfchangeHeaderDateTime !== -1) {
      const updatedIcons = [...header.center.icons];
      updatedIcons[indexOfchangeHeaderDateTime] = {
        ...updatedIcons[indexOfchangeHeaderDateTime],

        label:
          iconName === 'changeHeaderDate'
            ? dayjs(dateTime).format('dddd, DD. MMMM').toUpperCase()
            : `${dayjs(dateTime).format('HH:mm')} Uhr`,
      };

      const updatedHeaderData = {
        ...header,
        center: {
          ...header.center,
          icons: updatedIcons,
        },
      };

      setHeader(updatedHeaderData);
    }
  };

  const handleContextMenu = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setClicked(!clicked);
  };

  const contextMenuActions: HeaderContextMenuActions = {
    saveData: handleSaveData,
    resetCoords: resetCoords,
    toggleOpenSettings: () => props.handleChangeActiveWindow?.('settingsWindow'),
    toggleEditMode: toggleEditMode,
  };

  return (
    <div className="flex relative z-10 gap-5 justify-between px-6 py-1 w-full text-xs font-medium text-center text-white backdrop-blur-[32px] bg-black bg-opacity-40 max-md:flex-wrap max-md:px-5 max-md:max-w-full">
      <div className="flex gap-5 justify-between whitespace-nowrap shadow-sm">
        {header.left?.icons.map((icon, idx) => (
          <Fragment key={idx}>
            {icon.icon ? (
              <IconHeader
                key={idx}
                src={icon.icon}
                onClick={() => {
                  window.history.back();
                }}
              />
            ) : (
              <div key={idx}>{icon.label}</div>
            )}
            {header.left && header.left.icons.length - 1 !== idx && <Divider />}
          </Fragment>
        ))}
      </div>
      <div className="flex gap-5 justify-between shadow-sm">
        <div className="flex gap-1 justify-between whitespace-nowrap">
          {header.center?.icons.map((icon, idx) => (
            <Fragment key={idx}>
              {icon.label && icon.icon ? (
                <div className="flex justify-between">
                  <IconHeader key={idx} src={icon.icon} />
                  <div className="grow">{icon.label}</div>
                </div>
              ) : icon.icon ? (
                <IconHeader src={icon.icon} />
              ) : (
                <CustomProvider locale={deDe}>
                  <button onClick={() => toggleDateTimePickersOpen(icon.action)}>
                    {icon.label}
                  </button>
                  {icon.action === 'changeHeaderTime' && (
                    <DatePicker
                      hidden={true}
                      editable={false}
                      onOk={(date) => handleSelectTime(date, 'changeHeaderTime')}
                      format="HH:mm"
                      open={isTimePickerOpen}
                    />
                  )}
                  {icon.action === 'changeHeaderDate' && (
                    <DatePicker
                      hidden={true}
                      editable={false}
                      onOk={(date) => handleSelectTime(date, 'changeHeaderDate')}
                      format="dd.MM.yyyy"
                      open={isDatePickerOpen}
                    />
                  )}
                </CustomProvider>
              )}
              {header.center && header.center.icons.length !== idx && (
                <DividerTransparent />
              )}
            </Fragment>
          ))}
        </div>
      </div>
      <div className="flex gap-4 justify-between whitespace-nowrap shadow-sm">
        {header.right?.icons.map((icon, idx) =>
          icon.label && icon.icon ? (
            <div key={idx} className="flex gap-1 justify-between">
              <IconHeader
                key={idx}
                src={icon.icon}
                clicked={clicked}
                contextMenu={icon.contextMenu ? handleContextMenu : undefined}
              />
              <div className="grow">{icon.label}</div>
            </div>
          ) : icon.icon ? (
            <IconHeader
              key={idx}
              src={icon.icon}
              clicked={clicked}
              contextMenu={icon.contextMenu ? handleContextMenu : undefined}
              contextMenuActions={contextMenuActions}
            />
          ) : (
            <div key={idx}>{icon.label}</div>
          ),
        )}
      </div>
    </div>
  );
};
