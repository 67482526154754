import { Icon } from '../../types/generalTypes';

interface IconProps {
  icon: Icon;
  onClick?: () => void;
  size?: number;
}

export const SettingBarIcon: React.FC<IconProps> = ({ icon, onClick, size = '22' }) => {
  const src = icon.icon;
  const elementId = icon.elementId;

  return (
    <button
      id={elementId}
      onClick={onClick}
      className="flex justify-between whitespace-nowrap shadow-sm"
    >
      <img
        src={src}
        alt={src}
        className={`aspect-square`}
        style={{ width: `${size}px` }}
      />
    </button>
  );
};
