import { createPc } from './createPc';
import { uploadJson } from './uploadJson';
import { getPlanData } from './GetPlanData';

export const updatePlanWithNewPc = async (
  newPcName: string,
  jsonPath: string,
  day: string,
  scene: string,
) => {
  const response = await getPlanData();
  if (!response) return null;
  const { planResponse } = response;

  const dayPlan = planResponse[day];

  // currentScene
  const currentScene = dayPlan.children.map((s) => {
    if (s.name === scene) {
      s.children = [...s.children, createPc(newPcName, jsonPath)];
    }
    return s;
  });
  // add to end new created scene

  const newJson = {
    ...planResponse,
    [day]: {
      ...dayPlan,
      children: currentScene,
    },
  };

  uploadJson('plan', newJson);
};
