import axios from 'axios';

export async function uploadJson<T>(path: string, jsonData: T) {
  await axios.post(
    `https://proos-api.procorp.cz/json`,
    {
      path: path,
      json: jsonData,
    },
    {
      headers: {
        'api-key': 'wfA7kzXCgixU5s4BkVzb',
      },
    },
  );
}
