/**
 * Function adds string based on what is in the result.
 * @param base
 * @param result
 * @param keyPressedCount
 */

export const addOnKeyPress = (base: string, result: string, keyPressedCount = 0) => {
  if (keyPressedCount >= result.length) {
    return base;
  }
  return base + result[keyPressedCount];
};
