import React from 'react';
import { GraphWindowType } from '../../utils/types/data';

export const Graph = (props: { data: GraphWindowType }) => {
  return (
    <div
      className={
        props.data.scroll
          ? 'w-full  bg-white pb-7'
          : 'flex justify-center w-full h-full bg-white'
      }
    >
      <img
        src={props.data.graph[0]}
        className={
          props.data.scroll
            ? 'w-full  object-contain'
            : 'h-full object-contain object-center'
        }
        alt="graph"
      />
    </div>
  );
};
