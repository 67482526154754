import { Toggle } from 'rsuite';
import { HeaderContextMenuActions } from '../screen/Header';
import { useGlobalStates } from '../../states/useGlobalStates';

type HeaderContectMenuType = {
  contextMenuActions?: HeaderContextMenuActions;
};

type HeaderContextMenuOptions = {
  name: string;
  onClick?: () => void;
  type: 'button' | 'toggle';
}[];

export const HeaderContextMenu = ({ contextMenuActions }: HeaderContectMenuType) => {
  const isInEditMode = useGlobalStates((state) => state.isInEditMode);

  const item: HeaderContextMenuOptions = [
    {
      name: 'Save',
      onClick: contextMenuActions?.saveData,
      type: 'button',
    },
    {
      name: 'Reset',
      onClick: contextMenuActions?.resetCoords,
      type: 'button',
    },
    {
      name: 'Settings',
      onClick: contextMenuActions?.toggleOpenSettings,
      type: 'button',
    },
    {
      name: 'Editable',
      onClick: contextMenuActions?.toggleEditMode,
      type: 'toggle',
    },
  ];

  return (
    <ul className="absolute top-[24px] right-0 z-auto bg-headerContextMenu-background text-headerContextMenu-color rounded-b-lg border-headerContextMenu-borderColor border">
      {item.map((item, index) => {
        return (
          <li key={index}>
            {item.type === 'button' && (
              <button
                className="w-[132px] cursor-pointer py-2 pl-2 border-headerContextMenu-borderColor border text-left font-semibold"
                onClick={item.onClick}
              >
                {' '}
                {item.name}
              </button>
            )}
            {item.type === 'toggle' && (
              <div
                onClick={item.onClick}
                className="w-[132px]  flex items-center cursor-pointer justify-between py-1 pl-2 border-headerContextMenu-borderColor border font-semibold"
              >
                <p>{item.name}</p>
                <Toggle checked={isInEditMode} onClick={item.onClick} />
              </div>
            )}
          </li>
        );
      })}
    </ul>
  );
};
