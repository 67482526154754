import React from 'react';

export const FilemanagerNav = (props: { navItem: string[]; searchIcon: string }) => {
  return (
    <nav className="relative  px-2 pt-2">
      <div className="flex flex-row items-center justify-between gap-2 px-0.5 bg-fileManager-navBackground border rounded-md ">
        <ul className="flex flex-row items-center">
          {props.navItem.map((oneNav: string, key: number) => {
            return (
              <li
                className="flex justify-center items-center px-1  rounded ursor-pointer cursor-pointer"
                key={key}
              >
                {oneNav[0] === '/' ? (
                  <img className="w-4" src={oneNav} alt="icon" />
                ) : (
                  <span className="text-[.9rem] font-semibold text-fileManager-navTypography">
                    {oneNav}
                  </span>
                )}
              </li>
            );
          })}
        </ul>
        <span className="flex justify-center items-center px-1 w-6 h-6 rounded aspect-square bg-white bg-opacity-30 cursor-pointer cursor-pointer">
          {' '}
          <img className="" src={props.searchIcon} alt="img" />{' '}
        </span>
      </div>
      <div className="absolute top-[8px] left-[70%] w-[1px] h-[26px] bg-stone-300"></div>
    </nav>
  );
};
