import React, { useEffect, useState } from 'react';
import './App.css';
import { RouterProvider } from 'react-router-dom';
import router from './routes';
import { LoginModal } from './components/day-route/DesktopScreen/LoginModal';

function App() {
  const [isLogged, setIsLogged] = useState(false);

  const creds = localStorage.getItem('isLogged');
  useEffect(() => {
    if (creds) setIsLogged(true);
  }, [creds]);

  const onSubmit = () => {
    setIsLogged(true);
  };

  return (
    <>
      {isLogged ? <RouterProvider router={router} /> : <LoginModal onSubmit={onSubmit} />}
    </>
  );
}

export default App;
