import { create } from 'zustand';

type ForceReset = {
  forceReset: boolean;
  setForceReset: (state: boolean) => void;
};

export const useForceReset = create<ForceReset>((set) => ({
  forceReset: true,
  setForceReset: () =>
    set((state) => ({
      forceReset: !state.forceReset,
    })),
}));
