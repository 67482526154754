import { SettingBarIcon } from './SettingBarIcon';
import { SettingbarIcons } from './VideoChatClient';
import { Icon } from '../../types/generalTypes';

type SettingBarProps = {
  presentationImagesLength?: number;
  isPresentationMode?: boolean;
  settingBarIcons: SettingbarIcons;
  onSwitchPresentationMode?: () => void;
  type: 'videoPlayer' | 'videoChat';
};

export const SettingBar = ({
  onSwitchPresentationMode,
  settingBarIcons,
  type,
}: SettingBarProps) => {
  const handleIconClick = (icon: Icon) => {
    const iconAction = icon.action;
    console.log('Click');

    switch (iconAction) {
      case 'switchPresentingModeOn': {
        onSwitchPresentationMode?.();
        break;
      }
    }
  };

  console.log(settingBarIcons);

  return (
    <div
      className={`bg-black w-full  absolute bottom-0  flex-col justify-center items-center flex gap-3 py-4`}
    >
      {type === 'videoPlayer' && (
        <div className={'flex w-full text-white items-center gap-3 px-3'}>
          <div>00:00</div>
          <div className={'bg-gray-300 h-1 flex-1 rounded'}></div>
          <div>00:00</div>
        </div>
      )}

      <div className={' flex justify-between items-center px-3 box-border w-full'}>
        <div className={'flex gap-4 items-center'}>
          {settingBarIcons.iconsLeft.map((iconLeft, idx) => (
            <SettingBarIcon
              key={idx}
              icon={iconLeft}
              onClick={() => handleIconClick(iconLeft)}
              size={iconLeft.size}
            />
          ))}
        </div>

        <div className={'flex gap-4 items-center'}>
          {settingBarIcons.iconsCenter.map((iconCenter, idx) => (
            <SettingBarIcon
              key={idx}
              icon={iconCenter}
              onClick={() => handleIconClick(iconCenter)}
              size={iconCenter.size}
            />
          ))}
        </div>
        <div className={'flex gap-4 items-center'}>
          {settingBarIcons.iconsRight.map((iconRight, idx) => (
            <SettingBarIcon
              key={idx}
              icon={iconRight}
              onClick={() => handleIconClick(iconRight)}
              size={iconRight.size}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
