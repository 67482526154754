export const Input = (props: {
  searchInputValue?: string;
  icon?: string;
  placeholder?: string;
}) => {
  return (
    <div className="relative w-full">
      <input
        type="text"
        className="w-full py-1.5 px-5 shadow-md border rounded-full text-search-gray "
        placeholder={props.placeholder}
        value={props.searchInputValue}
      />
      <div className="absolute top-[5px] right-[20px]">
        <img className="w-[24px]" src={props.icon} alt="glass" />
      </div>
    </div>
  );
};
