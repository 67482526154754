import React from 'react';
import { Graph } from './Graph';
import { GraphWindowType } from '../../utils/types/data';

export const GraphWindow = (props: { data: GraphWindowType }) => {
  return (
    <div
      className={
        props.data.scroll ? 'w-full h-full overflow-y-auto bg-white' : 'w-full h-full'
      }
    >
      <Graph data={props.data} />
    </div>
  );
};
