import UrlBar from './UrlBar';
import { ArticleManage } from './ArticleManage';

import { SearchResultData } from '../../types/generalTypes';
import { SearchIcons } from '../../types/searchClientTypes';
import { TextAreaWrapper } from '../TextAreaWrapper';

import { Input } from './Input';

export const SearchResult = (props: { data: SearchResultData; icons: SearchIcons }) => {
  const searchResultData = props.data;

  const bottomText = props.data.children.searchresultBottom;

  let landscape: string[] = [];
  let portrait: string[] = [];

  for (let i = 0; i <= bottomText.length - 1; i++) {
    if (i >= 3) {
      portrait = [...portrait, bottomText[i]];
    } else {
      landscape = [...landscape, bottomText[i]];
    }
  }

  const searchResulthPath = `windowContents.searchResult.children.searchresultBottom`;

  return (
    <>
      <div className="w-full h-full flex-col content-center ">
        <UrlBar icons={props.icons} />
        {/* <SearchInput
          text={props.data.searchInput.text}
          icon={props.data.searchInput.icon}
          searchInputValue={searchInputValue}
        /> */}
        <main className="relative flex gap-20 w-full max-w-[1400px] mx-auto pb-10 px-10 ">
          <div className="flex-auto w-80 flex flex-col gap-4 pb-10">
            <div className="flex flex-row gap-5">
              <p className="text-4xl leading-none font-thin text-search-blue drop-shadow-md">
                {props.data.searchInput.text}
              </p>
              <div>
                <div className=" pb-5">
                  <Input
                    icon={props.data.searchInput.icon}
                    searchInputValue={props.data.searchInput.inputValue}
                  />
                </div>
                <div className="flex flex-col gap-5">
                  {searchResultData.children.bodyArticle.map((item, key) => {
                    return (
                      <ArticleManage
                        key={key}
                        idx={key}
                        component="SearchResult"
                        iconSrc={item.iconSrc}
                        hightText={item.hightText}
                        text={item.text}
                        category={item.category}
                        author={item.author}
                        date={item.date}
                        allArticleIcons={searchResultData.allArticleIcons}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="flex-auto w-24 flex flex-col  gap-2  py-20">
            <div className="sticky top-[15px] right-[0] ">
              <h2 className="text-lg font-bold text-[#0077B6] pb-2">
                {' '}
                {searchResultData.children.gallery.galleryHightText}{' '}
              </h2>
              <div className="flex flex-wrap  gap-2">
                <div className="flex flex-wrap  gap-2">
                  {searchResultData.children.gallery.photo.map((item, key) => (
                    <div className="w-[200px] h-[100px]" key={key}>
                      <img className="w-full h-full object-cover" src={item} alt="img" />
                    </div>
                  ))}
                </div>
                <div className="flex flex-col items-center gap-3 mt-3 ml-10">
                  <div className="w-80">
                    <Input
                      icon={props.data.searchInput.icon}
                      searchInputValue={props.data.firstAsideInput?.inputValue}
                    />
                  </div>
                  <div className="w-80">
                    <Input
                      icon={props.data.searchInput.icon}
                      searchInputValue={props.data.secondAsideInput?.inputValue}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <div className="absolute bottom-0 left-0 flex justify-between w-full px-4 text-sm bg-search-lightGray border-2">
          <div className="flex gap-2 text-search-gray">
            {landscape.map((oneText, index) => (
              <TextAreaWrapper
                key={index}
                Custom={<span></span>}
                path={`${searchResulthPath}.${index}`}
              >
                {oneText}
              </TextAreaWrapper>
            ))}
          </div>

          <div className="flex gap-2 text-search-gray">
            {portrait.map((oneText, index) => (
              <TextAreaWrapper
                key={index}
                Custom={<span></span>}
                path={`${searchResulthPath}.${index + 3}`}
              >
                {oneText}
              </TextAreaWrapper>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
