import React from 'react';
import { asideItemType } from '../../utils/types/data';

export const AsideNav = (props: { asideItem: asideItemType[] }) => {
  return (
    <aside>
      <div className="flex flex-col gap-2 pt-4">
        {props.asideItem.map((item, key) =>
          !item.hidden ? (
            <button className="flex gap-2 items-center" key={key}>
              <span>
                <img className="w-[30px]" src={item.icon} alt="icon" />
              </span>
              <span>{item.text}</span>
            </button>
          ) : null,
        )}
      </div>
    </aside>
  );
};
